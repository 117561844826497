import React, { Fragment, useContext, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import axios from "axios";
import cogoToast from "cogo-toast";
import awslogo from "../../assets/images/aws-logo.svg";
import azurelogo from "../../assets/images/azure-logo.svg";
import googlecloudlogo from "../../assets/images/gcp-logo.svg";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Button, Col, Row, Table, Spinner } from "reactstrap";
import { AuthContext, GeneralContext } from "../../context";
import { INSERT_ONE } from "../../gql/mutation";
import { crispStyle, uuidv4 } from "../../helpers/utils";
import { APP_CONFIG } from "../../config";

const useImperativeQuery = (query) => {
  const { refetch } = useQuery(query, { skip: true });

  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };

  return imperativelyCallQuery;
};

const QuoteRequestsView = (props) => {
  const { user } = useContext(AuthContext);
  const { enum_machine_operating_systems } = useContext(GeneralContext);

  const callQuery = useImperativeQuery(gql`
    query MyQuery($cluster: String, $cpu: Int) {
      object: csp_pricings(
        where: { cpu: { _eq: $cpu }, cluster: { _eq: $cluster } }
        distinct_on: memory
      ) {
        memory
      }
    }
  `);

  const new_machine = () => ({
    id: uuidv4(),
    cpu: "",
    memory: "",
    memory_list: [],
    storage: "",
    operating_system: "",
    performance: "",
    quantity: 1,
  });
  const [machines, setMachines] = useState([new_machine()]);
  const [geo, setGeo] = useState({
    label: "North America",
    value: "North America",
  });

  const [quoteEstimate, setQuoteEstimate] = useState();
  const [generateLoad, setGenerateLoad] = useState(false);
  const [cspLoad, setCSPLoad] = useState({
    azure: false,
    aws: false,
    gcp: false,
  });
  const [regionFilter, setRegionFilter] = useState({
    azure: false,
    aws: false,
    gcp: false,
  });

  const valid_machine = (m) =>
    m.cpu &&
    m.memory &&
    m.storage &&
    m.operating_system &&
    m.performance &&
    m.quantity
      ? true
      : false;
  const valid_machines = machines.filter((m) => valid_machine(m)).length;

  const geo_region = [
    {
      label: "North America",
      value: "North America",
    },
    {
      label: "Europe",
      value: "Europe",
    },
    {
      label: "Middle East",
      value: "Middle East",
    },
    {
      label: "Asia",
      value: "Asia",
    },
    {
      label: "Australia",
      value: "Australia",
    },
    {
      label: "South America",
      value: "South America",
    },
    {
      label: "South Africa",
      value: "South Africa",
    },
  ];

  const performance_options = [
    {
      label: "HDD",
      value: "hdd",
    },
    {
      label: "SSD",
      value: "ssd",
    },
  ];

  const cpu_options = [2, 4, 6, 8, 12, 16, 24, 32, 64, 96, 128, 192, 256];

  const storage_options = [
    4,
    6,
    8,
    12,
    16,
    24,
    32,
    64,
    96,
    128,
    192,
    256,
    512,
    1024,
    2048,
    4096,
    8192,
    16384,
    32768,
  ];

  const [insert_one] = useMutation(gql(INSERT_ONE("quotes")));
  const onSubmit = async () => {
    try {
      await insert_one({
        variables: {
          object: {
            quote_status: "open",
            quote_requests: {
              data: machines
                .filter((m) => valid_machine(m))
                .map((m) => ({
                  cpu: m.cpu.value,
                  memory: m.memory.value,
                  storage: m.storage.value,
                  operating_system: m.operating_system.value,
                  performance: m.performance.value,
                  quantity: m.quantity,
                })),
            },
          },
        },
      });
      cogoToast.success("Quote Submitted", { position: "top-right" });
      setMachines([new_machine()]);
    } catch (error) {
      console.error(error);
      cogoToast.error("Quote Submission Failed", { position: "top-right" });
    }
  };

  const handleGenerate = async () => {
    setGenerateLoad(true);

    axios
      .post(`${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/quote/get-estimate`, {
        machines: machines,
        geo: geo.value,
      })
      .then((response) => {
        if (response.data.success) {
          setQuoteEstimate(response.data.data);
          setGenerateLoad(false);
        }
      })
      .catch((error) => {
        setGenerateLoad(false);
        console.error("error", error);
        cogoToast.error("Something went wrong, please try again later");
      });
  };

  const handleChangeFilter = (data, csp_type) => {
    setCSPLoad({ ...cspLoad, [csp_type]: true });
    axios
      .post(
        `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/quote/get-estimate-filter`,
        {
          machines: machines,
          geo: geo.value,
          filter: {
            region: data.value,
            csp: csp_type,
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          setQuoteEstimate({
            ...quoteEstimate,
            quote: {
              ...quoteEstimate.quote,
              [csp_type]: response.data.data.quote[csp_type],
            },
          });
          setCSPLoad({ ...cspLoad, [csp_type]: false });
        }
      })
      .catch((error) => {
        setCSPLoad({ ...cspLoad, [csp_type]: false });
        console.error("error", error);
        cogoToast.error("Something went wrong, please try again later");
      });

    setRegionFilter({
      ...regionFilter,
      azure: data,
    });
  };

  return (
    <Fragment>
      <h6 className="mb-3">
        {/* <Link to="/quoten">LIST</Link> */}
        Welcome <b>{user?.name}</b>, Please follow these steps to get a Quote
        generated.
      </h6>
      <Row>
        <Col md="10"></Col>
        <Col>
          <div className="form-group">
            <label>Geographic region</label>
            <Select
              menuPosition={"fixed"}
              styles={crispStyle}
              options={geo_region}
              value={geo}
              onChange={(e) => setGeo(e)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            className="table table-bordered table-centered table-nowrap table-sm"
            style={{ width: "100%" }}
          >
            <thead className="thead-light">
              <tr>
                <th style={{ width: 33 }}>#</th>
                <th style={{ width: 220 }}>CPU</th>
                <th style={{ width: 220 }}>MEMORY (GB)</th>
                <th style={{ width: 220 }}>Operating System</th>
                <th style={{ width: 220 }}>Storage (GB)</th>
                <th>Performance</th>
                <th style={{ width: 100 }}>Quantity</th>
                {/* <th style={{ width: 33 }}></th> */}
                <th style={{ width: 33 }}></th>
              </tr>
            </thead>
            <tbody>
              {machines.map((m, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>
                    <Select
                      menuPosition={"fixed"}
                      styles={crispStyle}
                      options={[
                        ...cpu_options.map((ss) => ({ label: ss, value: ss })),
                      ]}
                      value={m.cpu}
                      onChange={async (e) => {
                        setMachines(
                          machines.map((mac) => {
                            if (mac.id === m.id) {
                              mac.cpu = e;
                              mac.memory = "";
                            }
                            return mac;
                          })
                        );
                        const { data, error } = await callQuery({
                          cluster: "North America",
                          cpu: e.value,
                        });

                        if (error) {
                          cogoToast.error(
                            "Something went wrong, please try again later"
                          );
                        } else {
                          setMachines(
                            machines.map((mac) => {
                              if (mac.id === m.id)
                                mac.memory_list = [
                                  ...data.object.map((ss) => ({
                                    label: ss.memory,
                                    value: ss.memory,
                                  })),
                                ];
                              return mac;
                            })
                          );
                        }
                      }}
                    />
                  </td>
                  <td>
                    <Select
                      menuPosition={"fixed"}
                      styles={crispStyle}
                      options={[...m.memory_list]}
                      value={
                        [...m.memory_list].find(
                          (xx) => xx.value === m.memory.value
                        )
                          ? [...m.memory_list].find(
                              (xx) => xx.value === m.memory.value
                            )
                          : null
                      }
                      onChange={(e) =>
                        setMachines(
                          machines.map((mac) => {
                            if (mac.id === m.id) mac.memory = e;
                            return mac;
                          })
                        )
                      }
                    />
                  </td>
                  <td>
                    <Select
                      menuPosition={"fixed"}
                      styles={crispStyle}
                      options={[
                        ...enum_machine_operating_systems.map((ss) => ({
                          label: ss.comment,
                          value: ss.value,
                        })),
                      ]}
                      value={m.operating_system}
                      onChange={(e) =>
                        setMachines(
                          machines.map((mac) => {
                            if (mac.id === m.id) mac.operating_system = e;
                            return mac;
                          })
                        )
                      }
                    />
                  </td>
                  <td>
                    <Select
                      menuPosition={"fixed"}
                      styles={crispStyle}
                      options={[
                        ...storage_options.map((ss) => ({
                          label: ss,
                          value: ss,
                        })),
                      ]}
                      value={m.storage}
                      onChange={(e) =>
                        setMachines(
                          machines.map((mac) => {
                            if (mac.id === m.id) mac.storage = e;
                            return mac;
                          })
                        )
                      }
                    />
                  </td>
                  <td>
                    <Select
                      menuPosition={"fixed"}
                      styles={crispStyle}
                      options={performance_options}
                      value={m.performance}
                      onChange={(e) =>
                        setMachines(
                          machines.map((mac) => {
                            if (mac.id === m.id) mac.performance = e;
                            return mac;
                          })
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      className="form-control form-control-sm"
                      type="number"
                      min={1}
                      size="small"
                      max={999999}
                      value={m.quantity}
                      onChange={(e) =>
                        setMachines(
                          machines.map((mac) => {
                            if (mac.id === m.id) mac.quantity = e.target.value;
                            return mac;
                          })
                        )
                      }
                    />
                  </td>
                  {/* <td>
                    {valid_machine(m) && (
                      <Button
                        type="button"
                        color="btn btn-sm btn-success"
                        disabled={true}
                      >
                        <i className="mdi mdi-check" />
                      </Button>
                    )}
                  </td> */}
                  <td>
                    <Button
                      type="button"
                      color="btn btn-sm btn-danger"
                      onClick={() =>
                        setMachines(machines.filter((ms) => ms.id !== m.id))
                      }
                    >
                      <i className="mdi mdi-close" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Button
            type="button"
            color="btn btn-sm btn-outline-primary"
            onClick={() => setMachines([...machines, new_machine()])}
          >
            <i className="mdi mdi-plus" /> Add Virtual Machine
          </Button>
          {valid_machines > 0 && (
            <Button
              disabled={generateLoad}
              type="button"
              color="btn btn-sm btn-primary float-right"
              onClick={handleGenerate}
            >
              {generateLoad ? (
                <>
                  <Spinner size="sm"></Spinner> Loading...
                </>
              ) : (
                "Generate Quote"
              )}
            </Button>
          )}
        </Col>
      </Row>
      <br />
      <h6>
        Valid Entries: <b>{valid_machines}</b>
      </h6>
      <hr></hr>
      {generateLoad ? (
        <div className="text-center">
          <Spinner size="sm"></Spinner> Loading...
        </div>
      ) : (
        <>
          {(() => {
            if (quoteEstimate?.regions && quoteEstimate.regions.length === 0) {
              return <div className="text-center">No matches found</div>;
            }
          })()}

          {(() => {
            if (
              quoteEstimate?.regions?.length > 0 &&
              quoteEstimate.regions.filter((xx) => xx.csp === "azure").length >
                0
            ) {
              return (
                <Row className="text-center">
                  {(() => {
                    const csp_filter = "azure";

                    const vm_list = quoteEstimate.quote[csp_filter];
                    const load_region = quoteEstimate.regions.filter(
                      (xx) => xx.csp === csp_filter
                    );

                    const default_region = load_region.find(
                      (r) => r.region === vm_list[0].region
                    ).region;

                    let t_payg = 0,
                      t_one_yr = 0,
                      t_three_yr = 0,
                      t_disk = 0;

                    if (vm_list.length > 0 && load_region.length > 0) {
                      return (
                        <Col>
                          <div
                            key={csp_filter}
                            className="p-2 rounded mb-3 d-flex justify-content-between"
                            style={{ width: "100%" }}
                          >
                            <img
                              src={azurelogo}
                              alt="client-img"
                              style={{
                                height: `${20}px`,
                              }}
                            />
                            {load_region.length > 0 && (
                              <div
                                className="text-left"
                                style={{ width: "150px" }}
                              >
                                <div>
                                  <Select
                                    menuPosition={"fixed"}
                                    styles={crispStyle}
                                    options={[
                                      ...load_region.map((ss) => ({
                                        label: ss.region,
                                        value: ss.region,
                                      })),
                                    ]}
                                    value={{
                                      label: default_region,
                                      value: default_region,
                                    }}
                                    onChange={(e) =>
                                      handleChangeFilter(e, csp_filter)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {cspLoad[csp_filter] ? (
                            <div className="text-center">
                              <Spinner size="sm"></Spinner> Loading...
                            </div>
                          ) : (
                            <Table
                              className="table table-centered table-bordered table-nowrap table-sm"
                              style={{ width: "100%" }}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th>Region</th>
                                  <th>Model</th>
                                  <th>CPU</th>
                                  <th>MEM</th>
                                  <th>Storage Model</th>
                                  <th>Storage</th>
                                  <th>Performance</th>
                                  <th className="bg-light">Qty</th>
                                  <th>Storage Price</th>
                                  <th>PAYG</th>
                                  <th>1 Yr</th>
                                  <th>3 Yr</th>
                                </tr>
                              </thead>

                              <tbody>
                                {vm_list.map((obj, i) => {
                                  t_payg += obj.payg * obj.quantity;
                                  t_one_yr += obj.one_yr * obj.quantity;
                                  t_three_yr += obj.three_yr * obj.quantity;
                                  t_disk += obj.disk_price * obj.quantity;

                                  return (
                                    <tr key={i}>
                                      <td>{obj.region}</td>
                                      <td>{obj.model}</td>
                                      <td>{obj.cpu}</td>
                                      <td>{obj.memory}</td>
                                      <td>{obj.disk_model}</td>
                                      <td>{obj.storage}</td>
                                      <td>{obj.performance}</td>
                                      <td>{obj.quantity}</td>
                                      <td>{obj.disk_price}</td>
                                      <td>{obj.payg}</td>
                                      <td>{obj.one_yr}</td>
                                      <td>{obj.three_yr}</td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <th colSpan="8" className="text-right pr-3">
                                    <b>TOTAL</b>
                                  </th>
                                  <th>{t_disk}</th>
                                  <th>{t_payg}</th>
                                  <th>{t_one_yr}</th>
                                  <th>{t_three_yr}</th>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </Col>
                      );
                    }
                  })()}
                </Row>
              );
            }
          })()}

          {(() => {
            if (
              quoteEstimate?.regions?.length > 0 &&
              quoteEstimate.regions.filter((xx) => xx.csp === "aws").length > 0
            ) {
              return (
                <Row className="text-center">
                  {(() => {
                    const csp_filter = "aws";

                    const vm_list = quoteEstimate.quote[csp_filter];
                    const load_region = quoteEstimate.regions.filter(
                      (xx) => xx.csp === csp_filter
                    );

                    const default_region = load_region.find(
                      (r) => r.region === vm_list[0].region
                    ).region;

                    let t_payg = 0,
                      t_one_yr = 0,
                      t_three_yr = 0,
                      t_disk = 0;

                    if (vm_list.length > 0 && load_region.length > 0) {
                      return (
                        <Col>
                          <div
                            key={csp_filter}
                            className="p-2 rounded mb-3 d-flex justify-content-between"
                            style={{ width: "100%" }}
                          >
                            <img
                              src={awslogo}
                              alt="client-img"
                              style={{
                                height: `${20}px`,
                              }}
                            />
                            {load_region.length > 0 && (
                              <div
                                className="text-left"
                                style={{ width: "150px" }}
                              >
                                <div>
                                  <Select
                                    menuPosition={"fixed"}
                                    styles={crispStyle}
                                    options={[
                                      ...load_region.map((ss) => ({
                                        label: ss.region,
                                        value: ss.region,
                                      })),
                                    ]}
                                    value={{
                                      label: default_region,
                                      value: default_region,
                                    }}
                                    onChange={(e) =>
                                      handleChangeFilter(e, csp_filter)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {cspLoad[csp_filter] ? (
                            <div className="text-center">
                              <Spinner size="sm"></Spinner> Loading...
                            </div>
                          ) : (
                            <Table
                              className="table table-centered table-bordered table-nowrap table-sm"
                              style={{ width: "100%" }}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th>Region</th>
                                  <th>Model</th>
                                  <th>CPU</th>
                                  <th>MEM</th>
                                  <th>Storage Model</th>
                                  <th>Storage</th>
                                  <th>Performance</th>
                                  <th className="bg-light">Qty</th>
                                  <th>Storage Price</th>
                                  <th>PAYG</th>
                                  <th>1 Yr</th>
                                  <th>3 Yr</th>
                                </tr>
                              </thead>

                              <tbody>
                                {vm_list.map((obj, i) => {
                                  t_payg += obj.payg * obj.quantity;
                                  t_one_yr += obj.one_yr * obj.quantity;
                                  t_three_yr += obj.three_yr * obj.quantity;
                                  t_disk += obj.disk_price * obj.quantity;

                                  return (
                                    <tr key={i}>
                                      <td>{obj.region}</td>
                                      <td>{obj.model}</td>
                                      <td>{obj.cpu}</td>
                                      <td>{obj.memory}</td>
                                      <td>{obj.disk_model}</td>
                                      <td>{obj.storage}</td>
                                      <td>{obj.performance}</td>
                                      <td>{obj.quantity}</td>
                                      <td>{obj.disk_price}</td>
                                      <td>{obj.payg}</td>
                                      <td>{obj.one_yr}</td>
                                      <td>{obj.three_yr}</td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <th colSpan="8" className="text-right pr-3">
                                    <b>TOTAL</b>
                                  </th>
                                  <th>{t_disk}</th>
                                  <th>{t_payg}</th>
                                  <th>{t_one_yr}</th>
                                  <th>{t_three_yr}</th>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </Col>
                      );
                    }
                  })()}
                </Row>
              );
            }
          })()}

          {(() => {
            if (
              quoteEstimate?.regions?.length > 0 &&
              quoteEstimate.regions.filter((xx) => xx.csp === "gcp").length > 0
            ) {
              return (
                <Row className="text-center">
                  {(() => {
                    const csp_filter = "gcp";

                    const vm_list = quoteEstimate.quote[csp_filter];
                    const load_region = quoteEstimate.regions.filter(
                      (xx) => xx.csp === csp_filter
                    );

                    const default_region = load_region.find(
                      (r) => r.region === vm_list[0].region
                    ).region;

                    let t_payg = 0,
                      t_one_yr = 0,
                      t_three_yr = 0,
                      t_disk = 0;

                    if (vm_list.length > 0 && load_region.length > 0) {
                      return (
                        <Col>
                          <div
                            key={csp_filter}
                            className="p-2 rounded mb-3 d-flex justify-content-between"
                            style={{ width: "100%" }}
                          >
                            <img
                              src={googlecloudlogo}
                              alt="client-img"
                              style={{
                                height: `${20}px`,
                              }}
                            />
                            {load_region.length > 0 && (
                              <div
                                className="text-left"
                                style={{ width: "150px" }}
                              >
                                <div>
                                  <Select
                                    menuPosition={"fixed"}
                                    styles={crispStyle}
                                    options={[
                                      ...load_region.map((ss) => ({
                                        label: ss.region,
                                        value: ss.region,
                                      })),
                                    ]}
                                    value={{
                                      label: default_region,
                                      value: default_region,
                                    }}
                                    onChange={(e) =>
                                      handleChangeFilter(e, csp_filter)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {cspLoad[csp_filter] ? (
                            <div className="text-center">
                              <Spinner size="sm"></Spinner> Loading...
                            </div>
                          ) : (
                            <Table
                              className="table table-centered table-bordered table-nowrap table-sm"
                              style={{ width: "100%" }}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th>Region</th>
                                  <th>Model</th>
                                  <th>CPU</th>
                                  <th>MEM</th>
                                  <th>Storage Model</th>
                                  <th>Storage</th>
                                  <th>Performance</th>
                                  <th className="bg-light">Qty</th>
                                  <th>Storage Price</th>
                                  <th>PAYG</th>
                                  <th>1 Yr</th>
                                  <th>3 Yr</th>
                                </tr>
                              </thead>

                              <tbody>
                                {vm_list.map((obj, i) => {
                                  t_payg += obj.payg * obj.quantity;
                                  t_one_yr += obj.one_yr * obj.quantity;
                                  t_three_yr += obj.three_yr * obj.quantity;
                                  t_disk += obj.disk_price * obj.quantity;

                                  return (
                                    <tr key={i}>
                                      <td>{obj.region}</td>
                                      <td>{obj.model}</td>
                                      <td>{obj.cpu}</td>
                                      <td>{obj.memory}</td>
                                      <td>{obj.disk_model}</td>
                                      <td>{obj.storage}</td>
                                      <td>{obj.performance}</td>
                                      <td>{obj.quantity}</td>
                                      <td>{obj.disk_price}</td>
                                      <td>{obj.payg}</td>
                                      <td>{obj.one_yr}</td>
                                      <td>{obj.three_yr}</td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <th colSpan="8" className="text-right pr-3">
                                    <b>TOTAL</b>
                                  </th>
                                  <th>{t_disk}</th>
                                  <th>{t_payg}</th>
                                  <th>{t_one_yr}</th>
                                  <th>{t_three_yr}</th>
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </Col>
                      );
                    }
                  })()}
                </Row>
              );
            }
          })()}
        </>
      )}
      <Row>
        <Col md={6}></Col>
        <Col>
          {valid_machines > 0 && (
            <Button
              type="button"
              color="btn btn-sm btn-primary float-right ml-3 "
              onClick={onSubmit}
            >
              SUBMIT QUOTE
            </Button>
          )}
          {valid_machines > 0 && (
            <Button
              type="button"
              color="btn btn-sm btn-warning float-right ml-3 "
              onClick={() => setMachines([new_machine()])}
            >
              RESET
            </Button>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
export default QuoteRequestsView;
