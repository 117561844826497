import React, { useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../../context";
import { logoutUser } from "../../store";

const Logout = () => {
	const { logoutUser } = useContext(AuthContext);

	logoutUser();
	return <></>;
};

export default withRouter(connect(null, { logoutUser })(Logout));
