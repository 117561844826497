export const actionTypes = {
  REGISTER_USER: "@@auth/REGISTER_USER",
  REGISTER_USER_SUCCESS: "@@auth/REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILED: "@@auth/REGISTER_USER_FAILED",
  LOGIN_USER: "@@auth/LOGIN_USER",
  LOGIN_USER_SUCCESS: "@@auth/LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILED: "@@auth/LOGIN_USER_FAILED",
  FORGOT_PASSWORD: "@@auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "@@auth/FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "@@auth/FORGOT_PASSWORD_FAILED",
  LOGOUT_USER: "@@auth/LOGOUT_USER",
};
