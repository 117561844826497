import React from "react";
import { Link } from "react-router-dom";
import Cost from "../../assets/images/icons/cost.svg";
import monitoring from "../../assets/images/icons/monitoring.svg";
import Orchestration from "../../assets/images/icons/orchestration.svg";
import Storage from "../../assets/images/icons/storage.svg";
import logoDark from "../../assets/images/logo-dark-full-211x50px.png";

const CustomerInfo = ({ type }) => {
	return (
		<React.Fragment>
			<div className="d-flex flex-column  justify-content-center h-100">
				<img src={logoDark} alt="logo" className="left-pane-white-logo mb-1" />
				{type === "CustomerSignup" && <h5 className="display-4 mb-1 mt-3 font-size-24">Here's what to expect with your free Sign up</h5>}
				{type === "CustomerLogin" && <h5 className="display-4 mb-1 mt-3 font-size-24">Start your free subscription plan</h5>}
				{type === "ProviderLogin" && <h5 className="display-4 mb-1 mt-3 font-size-24">Be part of a global ecosystem</h5>}
				{type === "ProviderSignup" && <h5 className="display-4 mb-1 mt-3 font-size-24">Here's what to expect when you join us</h5>}

				<p>
					You will receive 4 hrs of AI Enabled managed services on a daily basis for each of your servers. Below are the various services that
					will be provided as part of your free plan.
				</p>

				<br />
				<table className="table">
					<tbody>
						<tr>
							<td style={{ width: "40px" }}>
								{" "}
								<img src={Orchestration} alt="client-img" style={{ width: "30px" }} />
							</td>
							<td>
								Daily Support & Management
								<br />
								<span className="text-muted">Remote management and troubleshooting, Support via Chat</span>
							</td>
						</tr>
						<tr>
							<td style={{ width: "40px" }}>
								{" "}
								<img src={monitoring} alt="client-img" style={{ width: "30px" }} />
							</td>
							<td>
								Monitoring
								<br />
								<span className="text-muted">
									Server availability, vCPU , memory, disk IOPS , network collision and cloud service provider related events
								</span>
							</td>
						</tr>
						<tr>
							<td style={{ width: "40px" }}>
								{" "}
								<img src={Storage} alt="client-img" style={{ width: "30px" }} />
							</td>
							<td>
								Backup & Restore
								<br />
								<span className="text-muted">OS level backups/Restores (Weekly Full & incremental for rest of the week)</span>
							</td>
						</tr>
						<tr>
							<td style={{ width: "40px" }}>
								{" "}
								<img src={Cost} alt="client-img" style={{ width: "30px" }} />
							</td>
							<td>
								Cost Optimization
								<br />
								<span className="text-muted">Cloud cost budgeting, alerting and forecasting</span>
							</td>
						</tr>
					</tbody>
				</table>
				{type === "CustomerLogin" && (
					<div>
						<Link to="/auth/register/customer" className="btn btn-primary mt-1">
							Signup for free
						</Link>
					</div>
				)}

				{type === "CustomerSignup" && (
					<div>
						<h5 className="display-4 mb-3 mt-1 font-size-24">Next Steps </h5>
						Once you have signed up successfully, we’ll reach out to set you up with one of our product experts so that we can tailor your
						subscription to your unique environment and technical requirements.
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default CustomerInfo;
