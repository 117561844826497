import axios from "axios";
import cogoToast from "cogo-toast";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import logoDark from "../../assets/images/logo-dark-full-211x50px.png";
import Icon from "../../components/Icon";
import { APP_CONFIG, PATHS } from "../../config";
import { useFormValidation } from "../../hooks";

const PasswordReset = () => {
	const location = useLocation();
	const history = useHistory();
	const [verifying, set_verifying] = useState(false);

	const queryParams = queryString.parse(location.search);

	const [formFields, setFormFields] = useState({
		password: "",
		confirmPassword: "",
		customerId: "",
		isSubmitting: false,
	});

	const [formFieldErrors, onBlur] = useFormValidation(
		{
			password: "",
			confirmPassword: "",
		},
		formFields
	);

	const [passwordVisibility, setPasswordVisibility] = useState({
		password: false,
		confirmPassword: false,
	});

	const togglePasswordVisibility = (field) => {
		setPasswordVisibility({
			...passwordVisibility,
			[field]: !passwordVisibility[field],
		});
	};

	// pending | failed | success | completed
	const [verificationStatus, setVerificationStatus] = useState("pending");
	console.log("🚀 ~ file: PasswordReset.js ~ line 48 ~ verifying", verifying);

	useEffect(() => {
		(async function () {
			if (queryParams?.t && !verifying && verificationStatus === "pending") {
				console.log("🚀 ~ file: PasswordReset.js ~ line 53 ~ verifying", verifying);
				set_verifying(true);
				try {
					const response = await axios({
						method: "POST",
						url: `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/reset-password`,
						data: {
							action: "verify",
							t: queryParams.t,
						},
					});
					cogoToast.success(response.data.message, { position: "top-right" });
					setFormFields({ ...formFields, customerId: response.data.user_id });
					setVerificationStatus("success");
					set_verifying(false);
				} catch (err) {
					cogoToast.error(err.response.data.message, { position: "top-right" });
					setVerificationStatus("failed");
				}
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParams?.t]);

	useEffect(() => {
		if (verificationStatus === "success") {
			const timer = setTimeout(() => {
				setVerificationStatus("completed");
			}, 1500);
			return () => clearTimeout(timer);
		}
	}, [verificationStatus]);

	const onChange = (e) => {
		setFormFields({ ...formFields, [e.target.name]: e.target.value });
		onBlur(e);
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const { password, confirmPassword, customerId } = formFields;
		if (
			[...Object.values(formFieldErrors)].every((err) => {
				return err ? false : true;
			}) &&
			customerId &&
			password &&
			password === confirmPassword
		) {
			setFormFields({ ...formFields, isSubmitting: true });
			try {
				const response = await axios({
					method: "POST",
					url: `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/reset-password`,
					data: {
						action: "reset",
						t: queryParams.t,
						new_password: password,
						user_id: customerId,
					},
				});
				cogoToast.success(response.data.message, { position: "top-right" });
				history.push(PATHS.login);
			} catch (err) {
				if (err?.response?.data?.message) {
					cogoToast.error(err.response.data.message, { position: "top-right" });
				}
			}
			setFormFields({ ...formFields, isSubmitting: false });
		}
	};

	//   if query parameter is absent, redirect user
	if (!location.search || !queryParams || !queryParams?.t) {
		return <Redirect to={PATHS.authRedirect} />;
	}

	return (
		<>
			<main className="main auth">
				<div className="left-pane-white">
					<div className="d-flex flex-column align-items-center justify-content-center h-100">
						<img src={logoDark} alt="FlipServe logo" className="left-pane-white-logo mb-5" />
						<p className="display-4 mb-3">Defining iSaaS</p>
						<p className="text-center">
							Join the fastest growing digital platform for Infrastructure Services. Follow the easy sign up process and onboard your cloud
							services to our platform.
						</p>
					</div>
				</div>
				<div className="right-pane">
					{verificationStatus !== "completed" && (
						<div
							style={{ width: "100%", height: "100%" }}
							className="d-flex flex-column align-items-center justify-content-center user-select-none"
						>
							<img src={logoDark} alt="FlipServe logo" className="d-none right-pane-logo align-self-center" />
							{verificationStatus !== "completed" && verificationStatus === "pending" && (
								<>
									<Spinner animation="border" />
									<h3 className="mt-3 text-sm font-weight-bold text-uppercase">Verifying</h3>
								</>
							)}
							{verificationStatus !== "completed" && verificationStatus === "failed" && (
								<>
									<Icon type="warning" width="4rem" height="4rem" className="icon-danger" />
									<h3 className="mt-3 text-sm font-weight-bold text-uppercase text-danger">Verification Failed</h3>
									<div className="mt-5">
										<p className="text-center">
											<span>
												<Link to={PATHS.forgotPassowrd} className="font-weight-bold text-primary">
													Request for Password Reset
												</Link>
											</span>
										</p>
									</div>
								</>
							)}
							{verificationStatus !== "completed" && verificationStatus === "success" && (
								<>
									<Icon type="check" width="4rem" height="4rem" className="icon-success" />
									<h3 className="mt-3 text-sm font-weight-bold text-uppercase text-success">Verification Successful</h3>
								</>
							)}
						</div>
					)}

					{verificationStatus === "completed" && (
						<div className="d-flex flex-column justify-content-center h-100">
							<img src={logoDark} alt="FlipServe logo" className="d-none right-pane-logo align-self-center" />
							<div>
								<h3 className="text-dark display-4 mb-5 font-weight-bold">Reset Password</h3>
								<p className="mb-4 text-gray text-sm">
									<span>Enter a new password for your account</span>
								</p>
								<form onSubmit={onSubmit}>
									<div className={formFieldErrors.password ? "form-group has-error" : "form-group"}>
										<label htmlFor="password">Password</label>
										<div className="input-group">
											<input
												type={passwordVisibility.password ? "text" : "password"}
												name="password"
												id="password"
												className="form-control"
												placeholder=""
												value={formFields.password}
												onChange={onChange}
												onBlur={onBlur}
											/>
											<div className="input-group-append" onClick={() => togglePasswordVisibility("password")}>
												<i
													className={passwordVisibility.password ? "input-group-text fa fa-eye-slash" : "input-group-text fa fa-eye"}
													aria-hidden="true"
												></i>
											</div>
										</div>
										{formFieldErrors.password && <p className="form-validation-message">{formFieldErrors.password}</p>}
									</div>
									<div className={formFieldErrors.confirmPassword ? "form-group mb-2 has-error" : "form-group mb-2"}>
										<label htmlFor="confirmPassword">Confirm Password</label>
										<div className="input-group">
											<input
												type={passwordVisibility.confirmPassword ? "text" : "password"}
												name="confirmPassword"
												id="confirmPassword"
												className="form-control"
												placeholder=""
												value={formFields.confirmPassword}
												onChange={onChange}
												onBlur={onBlur}
											/>
											<div className="input-group-append" onClick={() => togglePasswordVisibility("confirmPassword")}>
												<i
													className={passwordVisibility.confirmPassword ? "input-group-text fa fa-eye-slash" : "input-group-text fa fa-eye"}
													aria-hidden="true"
												></i>
											</div>
										</div>
										{formFieldErrors.confirmPassword && <p className="form-validation-message">{formFieldErrors.confirmPassword}</p>}
									</div>
									<button type="submit" className="btn btn-block btn-primary" onClick={onSubmit} disabled={formFields.isSubmitting}>
										{formFields.isSubmitting ? <Spinner size="sm" role="status" /> : <span>Change Password</span>}
									</button>
								</form>
							</div>
						</div>
					)}
				</div>
			</main>
		</>
	);
};

export default PasswordReset;
