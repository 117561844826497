import _ from "lodash";
import React, { createContext, useReducer } from "react";

const initialGeneralState = {
	enum_alert_types: [],
	enum_countries: [],
	enum_csp_types: [],
	enum_currencies: [],
	enum_parameter_statuses: [],
	enum_parameter_types: [],
	enum_quote_request_levels: [],
	enum_quote_request_parameters: [],
	enum_quote_statuses: [],
	enum_recommendation_types: [],
	enum_roles: [],
	enum_machine_operating_systems: [],
	enum_machine_tiers: [],
	enum_machine_types: [],
	enum_ticket_priorities: [],
	enum_ticket_severities: [],
	enum_ticket_statuses: [],
	enum_ticket_types: [],
	enum_alert_types_map: {},
	enum_countries_map: {},
	enum_csp_types_map: {},
	enum_currencies_map: {},
	enum_parameter_statuses_map: {},
	enum_parameter_types_map: {},
	enum_quote_request_levels_map: {},
	enum_quote_request_parameters_map: {},
	enum_quote_statuses_map: {},
	enum_recommendation_types_map: {},
	enum_roles_map: {},
	enum_machine_operating_systems_map: {},
	enum_machine_tiers_map: {},
	enum_machine_types_map: {},
	enum_ticket_priorities_map: {},
	enum_ticket_severities_map: {},
	enum_ticket_statuses_map: {},
	enum_ticket_types_map: {},
	csp_regions_map: {},
	csp_server_configs_map: {},
	price_plans: [],
	country_states_map: {},
};

const generalReducer = (state, action) => {
	switch (action.type) {
		case "SET_ENUMS":
			return {
				...state,
				[action.field]: action.payload,
				[action.field + "_map"]: action.payload_map,
			};
		case "SET_GROUP_BY":
			return {
				...state,
				[action.field + "_map"]: _.groupBy(action.payload, action.group_by),
			};
		case "SET_DATA":
			return {
				...state,
				[action.field]: action.payload,
			};

		default:
			return state;
	}
};

const GeneralContext = createContext(initialGeneralState);
const GeneralProvider = ({ children }) => {
	const [state, dispatch] = useReducer(generalReducer, initialGeneralState);
	const dispatches = {
		setEnums(values, field) {
			let object_map = {};
			values.map((e) => {
				if (e.value) object_map[e.value] = e.comment;
				else if (e.id) object_map[e.id] = e.name;
				return e;
			});
			dispatch({
				type: "SET_ENUMS",
				payload: values.map((v) => ({ ...v, value: v.value ?? v.id, comment: v.comment ?? v.name })),
				field: field,
				payload_map: object_map,
			});
		},
		setGroupBy(data, field, group_by) {
			dispatch({
				type: "SET_GROUP_BY",
				payload: data,
				field: field,
				group_by: group_by,
			});
		},
		setData(data, field) {
			dispatch({
				type: "SET_DATA",
				payload: data,
				field: field,
			});
		},
	};
	return <GeneralContext.Provider value={{ ...state, ...dispatches }}>{children}</GeneralContext.Provider>;
};

export { GeneralContext, GeneralProvider };
