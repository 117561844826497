import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Redirect } from "react-router-dom";
import { Modal, ModalBody, Spinner } from "reactstrap";
import Illustration from "../../assets/images/icons/password.svg";
import logoDark from "../../assets/images/logo-dark-full-211x50px.png";
import { APP_CONFIG, PATHS } from "../../config";
import { AuthContext } from "../../context";
import CustomerInfo from "./CustomerInfo";


const VerifyAccount = () => {
	const { user, user_id, logoutUser, is_authenticated, tenant } = useContext(AuthContext);
	const [, setmodal] = useState(false);

	const [otp, setOtp] = useState("");
	const [requestId, setRequestId] = useState("");
	const [first_time, set_first_time] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const onChange = (otp) => {
		setOtp(otp);
	};

	useEffect(() => {
		if (user && user?.id && !user?.is_email_verified && first_time) {
			(async function makeHttpRequest() {
				try {
					const res = await axios({
						url: `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/verify`,
						method: "POST",
						data: {
							type: "request",
							user_id: user?.id,
						},
					});
					set_first_time(false);
					setRequestId(res.data.request_id);
					cogoToast.success(res.data.message, { position: "top-right" });
				} catch (err) {
					cogoToast.error(err?.response?.data?.message || "Something went wrong. Please reload page", { position: "top-right" });
					set_first_time(false);
				}
			})();
		}
	}, [first_time, user]);

	const resendCode = async () => {
		try {
			const res = await axios({
				url: `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/verify`,
				method: "POST",
				data: {
					type: "request",
					user_id: user_id,
				},
			});
			setIsSubmitting(false);
			setRequestId(res.data.request_id);
			cogoToast.success(res.data.message, { position: "top-right" });
		} catch (err) {
			cogoToast.error(err?.response?.data?.message || "Something went wrong. Please try again", { position: "top-right" });
			setIsSubmitting(false);
		}
	};

	const verifyEmail = async (e) => {
		e.preventDefault();
		if (otp && otp.length === 6) {
			const code = parseInt(otp, 10);
			setIsSubmitting(true);

			try {
				const res = await axios({
					url: `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/verify`,
					method: "POST",
					data: {
						type: "verification",
						user_id: user_id,
						code: code,
						request_id: requestId,
					},
				});
				setIsSubmitting(false);
				cogoToast.success(res.data.message, { position: "top-right" });
			} catch (err) {
				cogoToast.error(err?.response?.data?.message || "Something went wrong. Please try again", { position: "top-right" });
				setIsSubmitting(false);
			}
		}
	};

	const logoutOnClick = () => {
		logoutUser();
	};

	if (is_authenticated && user?.active && user?.is_email_verified && tenant && tenant?.services?.length === 0)
		return <Redirect to={PATHS.dashboard} />;
	else if (is_authenticated && user && user?.is_email_verified) return <Redirect to={PATHS.dashboard} />;

	return (
		<>
			<main className="main auth">
				<div className="left-pane-white">
				<CustomerInfo type="CustomerLogin" />
					{/*}
					<div className="d-flex flex-column align-items-center justify-content-center h-100">
						<img src={Illustration} style={{ width: "50%" }} alt="" />
					</div>
	*/}
				</div>
				<div className="right-pane">
					<div className="d-flex flex-column align-items-center justify-content-center h-100">
						<div>
							<img src={logoDark} alt="FlipServe logo" className="left-pane-white-logo mb-4" />
							{/*}
              <div className="icon-wrapper mx-auto">
                <Icon type="mail" width="3rem" height="3rem" />
              </div>
              <h3 className="text-dark display-4 font-weight-bold mb-5 text-center">
                Your account has been created!
              </h3>
  */}
							<h5 className="mb-1">To continue enter the verification code</h5>
							<p className="mb-4 text-gray text-sm">A verification code that has been sent to your email and phone.</p>

							<form>
								<div className="form-group">
									<label>Enter OTP Code</label>
									<div className="otp-input">
										<OtpInput value={otp} onChange={onChange} numInputs={6} isInputNum={true} separator={""} />
									</div>
								</div>
								<div className="mt-2 mb-4">
									<p className="d-flex align-items-center text-sm">
										<span>Didn't get an email or text? </span>
										<button type="button" className="btn btn-link font-weight-bold text-dark  p-0 ml-1" onClick={resendCode}>
											Resend Code
										</button>
									</p>
								</div>

								<button type="submit" className="btn btn-block btn-primary" onClick={verifyEmail} disabled={isSubmitting || first_time}>
									{isSubmitting ? <Spinner size="sm" role="status" /> : <span>Verify my Account</span>}
								</button>
							</form>
						</div>
					</div>
				</div>
			</main>

			<Modal size="md" isOpen={user?.is_email_verified} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabIndex="-1">
				<ModalBody>
					<div className="d-flex flex-column justify-content-center align-items-center">
						<div className="icon-wrapper display-4 text-info">
							<i className="bx bx-info-circle"></i>
						</div>
						<h4 className="text-center font-weight-bold logout-confirm-text mb-1">We are reviewing your request currently</h4>
						<p className="mb-5 text-center">
							<small>We will get in touch with you regarding the next steps shortly</small>
						</p>
						<div className="mb-2">
							<button
								type="button"
								className="btn btn-primary font-weight-bold btn-w-min"
								onClick={() => {
									setmodal(false);
									logoutOnClick();
								}}
							>
								Okay
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default VerifyAccount;
