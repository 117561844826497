import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import storage from "store2";
import logo from "../../assets/images/logo-grey.svg";
import { PATHS } from "../../config";
import { AuthContext } from "../../context";
import { useObjectByPkQuery } from "../../helpers/dbhooks";

const AuthRedirect = ({ location }) => {
	const { is_authenticated, setAuthContext } = useContext(AuthContext);
	const user_id = storage.has("user_id") ? storage.get("user_id") : "";
	const tenant_id = storage.has("tenant_id") ? storage.get("tenant_id") : "";
	const id_token = storage.has("id_token") ? storage.get("id_token") : "";

	const { object: user, loading: user_loading, error: user_error } = useObjectByPkQuery({
		id: user_id,
		entity: "users",
		fields: ` id active name email phone photo_url created_at is_customer is_email_verified is_phone_verified company
					address city state country zipcode
					permissions(where:{approved:{_eq:true}}) { tenant_id tenant { id name } role }`,
	});
	const { object: tenant, loading: tenant_loading, error: tenant_error } = useObjectByPkQuery({
		id: tenant_id,
		entity: "tenants",
		fields: ` id active name stripe_id
					services { id csp_tenant_id csp_type enum_csp_type { comment } service_subscriptions { id name csp_subscription_id }}
					permissions(where:{approved:{_eq:true}}) { user_id user { id name } role }`,
	});

	useEffect(() => {
		if (user_id && tenant_id && id_token && user?.active && tenant?.active) setAuthContext({ tenant, user, is_authenticated: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id_token, is_authenticated, tenant, tenant_id, user, user_id]);

	// if email not verify, redirect to Account Verification
	if (is_authenticated && user && user?.active && !user?.is_email_verified) return <Redirect to={PATHS.verifyAccount} />;
	/*
	****This is temporarily pointed to dashboard for the production push on Dec 15th ******
	*/
	if (is_authenticated && user && user?.active && user?.is_email_verified && tenant && tenant?.services?.length === 0)
		return <Redirect to={PATHS.dashboard} />;
		

	if (is_authenticated && user && user?.active && user?.is_email_verified && tenant && tenant?.services?.length > 0)
		return <Redirect to={location?.state?.from?.pathname ? location.state.from.pathname : PATHS.dashboard} />;

	//  if error, redirect to login
	if (!user_id && !is_authenticated) return <Redirect to={PATHS.login} />;
	// if (!user_id || !tenant_id || (!user && !user_loading && !user_error) || (!tenant && !tenant_loading && !tenant_error))
	// return <Redirect to={PATHS.login} />;

	return (
		<div style={{ width: "100vw", height: "100vh" }} className="d-flex flex-column align-items-center justify-content-center user-select-none">
			<div className="loader mb-3" />
			<img src={logo} alt="logo" className="loader-image" />
		</div>
	);
};
export default AuthRedirect;
