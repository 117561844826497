import { gql } from "@apollo/client";

export const GET_USER_BY_ID = gql`
	subscription GET_USER_BY_ID($id: uuid!) {
		user: users_by_pk(id: $id) {
			id
			active
			name
			email
			phone
			photo_url
			created_at
			is_customer
			is_email_verified
			is_phone_verified
			company
			address
			city
			state
			country
			zipcode
		}
	}
`;

export const GET_TENANT_BY_ID = gql`
	subscription GET_TENANT_BY_ID($id: uuid!) {
		tenant: tenants_by_pk(id: $id) {
			id
			active
			name
			stripe_id
			services {
				id
				csp_tenant_id
				csp_type
				enum_csp_type {
					comment
				}
			}
		}
	}
`;

export * from "./mutation";
export * from "./query";
export * from "./subscription";
