import React from "react";
import { Redirect } from "react-router-dom";
import Account from "../pages/Account/index";
import AuthRedirect from "../pages/Authentication/AuthRedirect";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import PasswordReset from "../pages/Authentication/PasswordReset";
import Register from "../pages/Authentication/Register";
import SelectRole from "../pages/Authentication/SelectRole";
import Checkout from "../pages/Checkout";
import Credentials from "../pages/Credential";
import Monitoring from "../pages/Monitoring";
import Placeholder from "../pages/Placeholder";
import QuoteNew from "../pages/QuoteNew";
import Quote from "../pages/Quote";
import Recommendations from "../pages/Recommendations";
import ServerDetail from "../pages/Servers/detail";
import Setup from "../pages/Setup";
import Subscriptions from "../pages/Subscriptions";
import Tickets from "../pages/Tickets";
import Reset from "../pages/Reset";

import TicketDetail from "../pages/Tickets/detail";
import BillingAddress from "../pages/Account/BillingAddress";
import ChangePassword from "../pages/Account/ChangePassword";
import Invoices from "../pages/Account/Invoices";
import Members from "../pages/Account/Members";
import Permissions from "../pages/Account/Permissions";
import Profile from "../pages/Account/Profile";

const authRoutes = [
  { path: "/dashboard" },
  { path: "/subscriptions", exact: true, component: Placeholder },
  { path: "/subscriptions/:id", component: Placeholder },

  { path: "/credentials", exact: true, component: Placeholder },
  { path: "/checkout", component: Placeholder },

  { path: "/servers/:id/monitoring", component: Placeholder },
  { path: "/servers/:id/backups", component: Placeholder },
  { path: "/servers/:id/restores", component: Placeholder },
  { path: "/servers/:id/events", component: Placeholder },
  { path: "/servers/:id/recommendations", component: Placeholder },

  { path: "/monitoring", exact: true, component: Placeholder },
  { path: "/recommendations", exact: true, component: Placeholder },

  { path: "/tickets", exact: true, component: Placeholder },
  { path: "/tickets/:id", component: Placeholder },
  { path: "/support", exact: true, component: Placeholder },
  { path: "/alerts", exact: true, component: Placeholder },
  { path: "/alerts/:id", component: Placeholder },

  { path: "/quote", exact: true, component: Placeholder },
  { path: "/quoten", exact: true, component: Placeholder },
  { path: "/quote/:id", component: Placeholder },

  { path: "/account/*", component: Placeholder },
  { path: "/reset", component: Placeholder },

  { path: "/setup", exact: true, component: Placeholder },

  /*

  { path: "/subscriptions", exact: true, component: Subscriptions },
  { path: "/subscriptions/:id", component: Subscriptions },

  { path: "/credentials", exact: true, component: Credentials },
  { path: "/checkout", component: Checkout },

  { path: "/servers/:id/monitoring", component: ServerDetail },
  { path: "/servers/:id/backups", component: ServerDetail },
  { path: "/servers/:id/restores", component: ServerDetail },
  { path: "/servers/:id/events", component: ServerDetail },
  { path: "/servers/:id/recommendations", component: ServerDetail },

  { path: "/monitoring", exact: true, component: Monitoring },
  { path: "/recommendations", exact: true, component: Recommendations },

  { path: "/tickets", exact: true, component: Tickets },
  { path: "/tickets/:id", component: TicketDetail },
  { path: "/support", exact: true, component: Placeholder },
  { path: "/alerts", exact: true, component: Placeholder },
  { path: "/alerts/:id", component: Placeholder },

  { path: "/quote", exact: true, component: QuoteNew },
  { path: "/quoten", exact: true, component: Quote },
  { path: "/quote/:id", component: Quote },

  { path: "/account/*", component: Account },
  { path: "/reset", component: Reset },

  { path: "/setup", exact: true, component: Setup },
  */

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const nonAuthRoutes = [
  { path: "/logout", exact: true, component: Logout },
  { path: "/auth/login", exact: true, component: Login },
  { path: "/auth/forgot-password", exact: true, component: ForgetPwd },
  { path: "/auth/register/customer", exact: true, component: Register },
  { path: "/auth/register/provider", exact: true, component: Register },
  { path: "/auth/select-role", exact: true, component: SelectRole },
  { path: "/auth/redirect", exact: true, component: AuthRedirect },
  { path: "/pwd/reset", exact: true, component: PasswordReset },
];

export { authRoutes, nonAuthRoutes };
