import {
  ApolloClient,
  ApolloProvider,
  gql,
  HttpLink,
  InMemoryCache,
  split,
  useQuery,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/scss/theme.scss";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/VerticalLayout/";
import { APP_CONFIG, PATHS } from "./config";
import { AuthContext, GeneralContext } from "./context";
import {
  useObjectByPkQuery,
  useObjectByPkSubscription,
} from "./helpers/dbhooks";
import "./i18n";
import "./index.css";
import VerifyAccount from "./pages/Authentication/VerifyAccount";
import { authRoutes, nonAuthRoutes } from "./routes/allRoutes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import Dashboard from "./pages/Dashboard";

const stripePromise = loadStripe(
  "pk_test_51HMPQ8BYMuUCOqA2CBXXmvY1Ff31Ko0Lt2ZSsvueUtmevJ7eB6ihLawKWJXmiaC14k3zc6cenggHirhW9uX7Ep5600S8D99hNL"
);

export const useApolloClient = (id_token, user_role) => {
  let headers = { "content-type": "application/json" };
  if (id_token) headers.Authorization = `Bearer ${id_token}`;
  //if (user_role) headers["X-Hasura-Role"] = user_role;
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      new WebSocketLink({
        uri: APP_CONFIG.REACT_APP_HASURA_URL_WS,
        options: {
          lazy: true,
          reconnect: true,
          connectionParams: async () => {
            return { headers };
          },
        },
      }),
      new HttpLink({
        uri: APP_CONFIG.REACT_APP_HASURA_URL,
        headers: headers,
      })
    ),
  });
};

const App = (props) => {
  const { setEnums, setGroupBy, setData } = useContext(GeneralContext);
  const {
    user_id,
    tenant_id,
    is_authenticated,
    setAuthContext,
    id_token,
    user,
    tenant,
    logoutUser,
  } = useContext(AuthContext);

  const { object: updated_user } = useObjectByPkSubscription({
    id: user_id,
    entity: "users",
    fields: ` id active name email phone photo_url created_at is_customer is_email_verified is_phone_verified company
					address city state country zipcode
					permissions(where:{approved:{_eq:true}}) { tenant_id tenant { id name } role }`,
  });
  const { object: updated_tenant } = useObjectByPkQuery({
    id: tenant_id,
    entity: "tenants",
    fields: ` id active name stripe_id
					services { id csp_tenant_id csp_type enum_csp_type { comment } service_subscriptions { id name csp_subscription_id }}
					permissions(where:{approved:{_eq:true}}) { user_id user { id name } role }`,
  });

  useEffect(() => {
    if (
      is_authenticated &&
      tenant_id &&
      id_token &&
      user_id &&
      updated_tenant &&
      updated_user &&
      (!_.isEqual(tenant, updated_tenant) || !_.isEqual(user, updated_user))
    )
      setAuthContext({ tenant: updated_tenant, user: updated_user });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id_token,
    is_authenticated,
    tenant,
    tenant_id,
    updated_tenant,
    updated_user,
    user,
    user_id,
  ]);

  const { data, loading, error } = useQuery(
    gql(` query getEnumValues{
			enum_alert_types(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_countries(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_csp_types(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_currencies(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_parameter_statuses(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_parameter_types(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_quote_request_levels(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_quote_request_parameters(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_quote_statuses(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_recommendation_types(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_roles(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_machine_operating_systems(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_machine_tiers(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_machine_types(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_ticket_priorities(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_ticket_severities(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_ticket_statuses(order_by: {comment: asc_nulls_last}) {id:value value comment}
			enum_ticket_types(order_by: {comment: asc_nulls_last}) {id:value value comment}
			country_states(order_by:{name:asc}){id name country_code}
			csp_regions{id csp_type name code}
			csp_server_configs{id csp_type name family vCPUs ram storage}
			price_plans{id name stripe_price_id cost support color}
		}`)
  );

  // SET ENUM DATA
  useEffect(() => {
    if (
      error &&
      String(error).includes(
        "Could not verify JWT: JWSError JWSInvalidSignature"
      )
    )
      logoutUser();
    if (!loading && !error && data) {
      if (data?.enum_alert_types?.length > 0)
        setEnums(data?.enum_alert_types, "enum_alert_types");
      if (data?.enum_countries?.length > 0)
        setEnums(data?.enum_countries, "enum_countries");
      if (data?.enum_csp_types?.length > 0)
        setEnums(data?.enum_csp_types, "enum_csp_types");
      if (data?.enum_currencies?.length > 0)
        setEnums(data?.enum_currencies, "enum_currencies");
      if (data?.enum_parameter_statuses?.length > 0)
        setEnums(data?.enum_parameter_statuses, "enum_parameter_statuses");
      if (data?.enum_parameter_types?.length > 0)
        setEnums(data?.enum_parameter_types, "enum_parameter_types");
      if (data?.enum_quote_request_levels?.length > 0)
        setEnums(data?.enum_quote_request_levels, "enum_quote_request_levels");
      if (data?.enum_quote_request_parameters?.length > 0)
        setEnums(
          data?.enum_quote_request_parameters,
          "enum_quote_request_parameters"
        );
      if (data?.enum_quote_statuses?.length > 0)
        setEnums(data?.enum_quote_statuses, "enum_quote_statuses");
      if (data?.enum_recommendation_types?.length > 0)
        setEnums(data?.enum_recommendation_types, "enum_recommendation_types");
      if (data?.enum_roles?.length > 0)
        setEnums(data?.enum_roles, "enum_roles");
      if (data?.enum_machine_operating_systems?.length > 0)
        setEnums(
          data?.enum_machine_operating_systems,
          "enum_machine_operating_systems"
        );
      if (data?.enum_machine_tiers?.length > 0)
        setEnums(data?.enum_machine_tiers, "enum_machine_tiers");
      if (data?.enum_machine_types?.length > 0)
        setEnums(data?.enum_machine_types, "enum_machine_types");
      if (data?.enum_ticket_priorities?.length > 0)
        setEnums(data?.enum_ticket_priorities, "enum_ticket_priorities");
      if (data?.enum_ticket_severities?.length > 0)
        setEnums(data?.enum_ticket_severities, "enum_ticket_severities");
      if (data?.enum_ticket_statuses?.length > 0)
        setEnums(data?.enum_ticket_statuses, "enum_ticket_statuses");
      if (data?.enum_ticket_types?.length > 0)
        setEnums(data?.enum_ticket_types, "enum_ticket_types");

      if (data?.price_plans?.length > 0)
        setData(data?.price_plans, "price_plans");

      if (data?.country_states?.length > 0)
        setGroupBy(data?.country_states, "country_states", "country_code");
      if (data?.csp_regions?.length > 0)
        setGroupBy(data?.csp_regions, "csp_regions", "csp_type");
      if (data?.csp_server_configs?.length > 0)
        setGroupBy(data?.csp_server_configs, "csp_server_configs", "csp_type");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data, error]);

  if (
    is_authenticated &&
    user?.email &&
    user?.name &&
    user?.is_phone_verified &&
    user?.is_email_verified
  ) {
    //Show intercom only on the servers
    if (!(window.location.origin.indexOf("local") > -1)) {
      window.Intercom("boot", {
        app_id: APP_CONFIG.REACT_APP_INTERCOM_ID,
        name: user?.name, // Full name
        email: user?.email, // Signup date as a Unix timestamp
      });
    }
  }

  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );

  return (
    <React.Fragment>
      <Elements stripe={stripePromise}>
        <Router>
          <Switch>
            {nonAuthRoutes.map((route, idx) => (
              <NonAuthmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
              />
            ))}
            <Authmiddleware
              path={PATHS.verifyAccount}
              layout={NonAuthLayout}
              component={VerifyAccount}
            />

            <Layout>
              <Dashboard></Dashboard>
              {authRoutes.map((route, idx) => (
                <Authmiddleware
                  exact={true}
                  key={idx}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Layout>
          </Switch>
        </Router>
      </Elements>
    </React.Fragment>
  );
};

const Client = (props) => {
  const { id_token } = useContext(AuthContext);
  return (
    <ApolloProvider client={useApolloClient(id_token)}>
      <App {...props} />
    </ApolloProvider>
  );
};

const mapStateToProps = (state) => ({ layout: state.Layout });
export default connect(mapStateToProps, null)(Client);
