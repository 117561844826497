import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { postFakeLogin, postJwtLogin } from "../../../helpers/fakebackend_helper";
//Include Both Helper File with needed methods
import { apiError, loginSuccess } from "./actions";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";

function* loginUser({ payload: { user, history } }) {
	try {
		if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
		} else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
			const response = yield call(postJwtLogin, "/post-jwt-login", { email: user?.email, password: user?.password });
			localStorage.setItem("authUser", JSON.stringify(response));
			yield put(loginSuccess(response));
		} else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
			const response = yield call(postFakeLogin, "/post-fake-login", { email: user?.email, password: user?.password });
			localStorage.setItem("authUser", JSON.stringify(response));
			yield put(loginSuccess(response));
		}
		history.push("/dashboard");
	} catch (error) {
		yield put(apiError(error));
	}
}

function* logoutUser({ payload: { history } }) {
	try {
		localStorage.removeItem("authUser");

		if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
		}
		history.push("/login");
	} catch (error) {
		yield put(apiError(error));
	}
}

export function* watchUserLogin() {
	yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
	yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
	yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
