const { actionTypes } = require("./types");

const initialAuthState = {
  user: undefined,
  isAuthenticated: false,
};

const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_USER:
      break;
    case actionTypes.REGISTER_USER_SUCCESS:
      break;
    default:
      return state;
  }
};

export default authReducer;
