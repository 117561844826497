import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { all } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";

// Reducers
import Layout from "./layout/reducer";
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Auth from "./auth/reducer";

// Sagas
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";

// Actions
export * from "./layout/actions";
export * from "./auth/register/actions";
export * from "./auth/login/actions";
export * from "./auth/forgetpwd/actions";
export * from "./auth/profile/actions";

const rootReducer = combineReducers({
  Auth,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
});

function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
  ]);
}

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default store;
