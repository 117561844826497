import React, { useContext } from "react";
import awslogo from "../../assets/images/aws-logo.svg";
import azurelogo from "../../assets/images/azure-logo.svg";
import googlecloudlogo from "../../assets/images/gcp-logo.svg";
import { GeneralContext } from "../../context";

export const SelectCSP = ({
  onChange,
  value,
  rightAligned,
  centerAligned,
  width = 180,
  factor = 1,
}) => {
  const { enum_csp_types } = useContext(GeneralContext);

  return (
    <div
      className={`d-flex ${rightAligned ? "float-right" : ""} ${
        centerAligned ? "justify-content-center" : ""
      } `}
    >
      {(() => {
        const ordered = [];
        ["azure", "aws", "gcp"].map((k) => {
          enum_csp_types.length &&
            ordered.push(enum_csp_types.find((x) => x.value === k));
          return null;
        });

        return ordered.map((csp, i) => (
          <>
            {csp.value === "azure" && (
              <div
                key={i}
                className={`p-2 rounded ${
                  rightAligned ? "ml-2" : "mr-2"
                } client-logo ${
                  csp.value === value
                    ? "border border-primary"
                    : "border border-light"
                }`}
                onClick={() => onChange(csp.value)}
                style={{
                  width: width * factor,
                  background: "linear-gradient(0deg, #34b8ff4d, transparent)",
                }}
              >
                <img
                  src={azurelogo}
                  alt="client-img"
                  style={{
                    height: `${20 * factor}px`,
                    margin: 5,
                    border: csp.value === value,
                  }}
                />
              </div>
            )}
            {csp.value === "aws" && (
              <div
                key={i}
                className={`p-2 rounded ${
                  rightAligned ? "ml-2" : "mr-2"
                } client-logo ${
                  csp.value === value
                    ? "border border-primary"
                    : "border border-light"
                }`}
                onClick={() => onChange(csp.value)}
                style={{
                  width: width * factor,
                  background:
                    "linear-gradient(0deg, rgb(228 136 31 / 40%), transparent)",
                }}
              >
                <img
                  src={awslogo}
                  alt="client-img"
                  style={{
                    height: `${25 * factor}px`,
                    margin: 5,
                    border: csp.value === value,
                  }}
                />
              </div>
            )}
            {csp.value === "gcp" && (
              <div
                key={i}
                className={`p-2 rounded ${
                  rightAligned ? "ml-2" : "mr-2"
                } client-logo ${
                  csp.value === value
                    ? "border border-primary"
                    : "border border-light"
                }`}
                onClick={() => onChange(csp.value)}
                style={{
                  width: width * factor,
                  background:
                    "linear-gradient(0deg, rgb(125 125 125 / 47%), transparent)",
                }}
              >
                <img
                  src={googlecloudlogo}
                  alt="client-img"
                  style={{
                    height: `${20 * factor}px`,
                    margin: 5,
                    border: csp.value === value,
                  }}
                />
              </div>
            )}
          </>
        ));
      })()}
    </div>
  );
};

export const GetLogoCSP = (csp_type, rightAligned, width) => (
  <div className={`d-flex p-2 ${rightAligned ? "float-right" : ""}`}>
    {csp_type === "azure" && (
      <img
        src={azurelogo}
        alt="client-img"
        style={{ width: width ?? "80px" }}
      />
    )}
    {csp_type === "aws" && (
      <img src={awslogo} alt="client-img" style={{ width: width ?? "50px" }} />
    )}
    {csp_type === "gcp" && (
      <img
        src={googlecloudlogo}
        alt="client-img"
        style={{ width: width ?? "130px", marginTop: "5px" }}
      />
    )}
  </div>
);