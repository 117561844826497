import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import logoDark from "../../assets/images/logo-dark-full-211x50px.png";
import { APP_CONFIG, PATHS } from "../../config";
import { AuthContext } from "../../context";
import { validateEmail } from "../../helpers";
import CustomerInfo from "./CustomerInfo";

const Login = ({ history }) => {
	const { is_authenticated, setAuthContext } = useContext(AuthContext);

	const [formFields, setFormFields] = useState({
		email: "",
		password: "",
		isSubmitting: false,
	});

	const [formFieldErrors, setFormFieldErrors] = useState({
		email: "",
		password: "",
	});

	const [passwordVisibility, togglePasswordVisibility] = useState(false);

	const onBlur = (e) => {
		switch (e.target.name) {
			case "email":
				if (!e.target.value) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Please enter an email address",
					});
				} else if (e.target.value && !validateEmail(e.target.value)) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Please enter a valid email address",
					});
				} else {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "",
					});
				}
				break;
			case "password":
				if (!e.target.value) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Kindly enter a password",
					});
				} else {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "",
					});
				}
				break;
			default:
				break;
		}
	};

	const onChange = (e) => {
		setFormFields({ ...formFields, [e.target.name]: e.target.value });
		onBlur(e);
	};

	const validateFormData = (data) => {
		const { email, password } = data;
		let emailMsg, passwordMsg;

		if (!email) {
			emailMsg = "Please enter an email address";
		} else if (email && !validateEmail(email)) {
			emailMsg = "Please enter a valid email address";
		} else {
			emailMsg = "";
		}

		if (!password) {
			passwordMsg = "Kindly enter a password";
		} else {
			passwordMsg = "";
		}

		setFormFieldErrors({
			...formFieldErrors,
			email: emailMsg,
			password: passwordMsg,
		});
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const { email, password } = formFields;

		if (
			[...Object.values(formFieldErrors)].every((err) => {
				return err ? false : true;
			}) &&
			email &&
			password
		) {
			setFormFields({ ...formFields, isSubmitting: true });

			try {
				const response = await axios({
					method: "POST",
					url: `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/login`,
					data: { email, password },
				});
				if (response?.data?.success) setAuthContext(response?.data?.data || {});
				else throw response;
			} catch (err) {
				console.log("Login -> err", err);
				if (err?.response?.data) {
					if (err?.response?.data?.message === "Wrong email or password.") {
						setFormFieldErrors({
							...formFieldErrors,
							email: err?.response?.data?.message,
						});
					}
				} else {
					cogoToast.error("Something went wrong. Please try again", {
						position: "top-right",
					});
				}
			}

			setFormFields({ ...formFields, isSubmitting: false });
		} else {
			validateFormData(formFields);
		}
	};

	if (is_authenticated) return <Redirect to={PATHS.authRedirect} />;

	return (
		<>
			<main className="main auth">
				<div className="left-pane-white">
					<CustomerInfo type="CustomerLogin" />
				</div>
				<div className="right-pane">
					<div className="d-flex flex-column justify-content-center h-100">
						<img src={logoDark} alt="FlipServe logo" className="d-none right-pane-logo align-self-center" />

						<div>
							<h3 className="text-dark display-4 mb-3 font-weight-bold">Log in</h3>
							<form onSubmit={onSubmit}>
								<div className={formFieldErrors.email ? "form-group has-error" : "form-group"}>
									<label htmlFor="email">Email ID</label>
									<input
										type="email"
										name="email"
										id="email"
										className="form-control"
										placeholder="name@domain.com"
										value={formFields.email}
										onChange={onChange}
										onBlur={onBlur}
									/>
									{formFieldErrors.email && <p className="form-validation-message">{formFieldErrors.email}</p>}
								</div>
								<div className={formFieldErrors.password ? "form-group has-error" : "form-group"}>
									<label htmlFor="password">Password</label>
									<div className="input-group">
										<input
											type={passwordVisibility ? "text" : "password"}
											name="password"
											id="password"
											className="form-control"
											placeholder=""
											value={formFields.password}
											onChange={onChange}
											onBlur={onBlur}
										/>
										<div className="input-group-append" onClick={() => togglePasswordVisibility(!passwordVisibility)}>
											<i
												className={passwordVisibility ? "input-group-text fa fa-eye-slash" : "input-group-text fa fa-eye"}
												aria-hidden="true"
											></i>
										</div>
									</div>
									{formFieldErrors.password && <p className="form-validation-message">{formFieldErrors.password}</p>}
								</div>
								<div className="mt-2 mb-4">
									<p className="d-flex align-items-center text-sm justify-content-end">
										<Link to="/auth/forgot-password">Forgot Password?</Link>
									</p>
								</div>
								<button type="submit" className="btn btn-block btn-primary" onClick={onSubmit} disabled={formFields.isSubmitting}>
									{formFields.isSubmitting ? <Spinner size="sm" role="status" /> : <span>Log In</span>}
								</button>
							</form>
						</div>
						<div className="mt-3">
							<p className="text-center">
								Not a customer?{" "}
								<span>
									<Link to="/auth/register/customer" className=" text-primary ">
										Signup for free
									</Link>
								</span>
							</p>
						</div>
						{/*}
						<div className="mt-3">
							<p className="text-center">
								<span>
									<Link to="/auth/register/provider" className=" text-primary ">
										Join our provider network
									</Link>
								</span>
							</p>
						</div>
						*/}
					</div>
				</div>
			</main>
		</>
	);
};

export default Login;
