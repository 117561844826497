import classnames from "classnames";
import React, { useState } from "react";
import Iframe from "react-iframe";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import "./section.scss";

export const PowerBI = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [loadTab1, setLoadTab1] = useState(true);

  const [loadTab2, setLoadTab2] = useState(false);
  const [loadTab3, setLoadTab3] = useState(false);
  const [loadTab4, setLoadTab4] = useState(false);
  const [loadTab5, setLoadTab5] = useState(false);
  const [loadTab6, setLoadTab6] = useState(false);

  function toggle(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    if (tab === "1") {
      setLoadTab1(true);
    }
    if (tab === "2") {
      setLoadTab2(true);
    }
    if (tab === "3") {
      setLoadTab3(true);
    }
    if (tab === "4") {
      setLoadTab4(true);
    }
    if (tab === "5") {
      setLoadTab5(true);
    }
    if (tab === "6") {
      setLoadTab6(true);
    }
  }

  return (
    <React.Fragment>
      <Row
        key="row"
        className="align-items-center p-3"
        style={{ marginTop: "-50px" }}
      >
        <Col lg="12" key="row">
          {/*}
                            <Alert color="info" className="mb-2" role="alert">
                                <i className="mdi mdi-alert-circle-outline mr-2"></i>
                                This is a sample dashboard. Please get in touch with support@flipserve.com to setup the dashboard for your account.
                            </Alert>
                            */}

          <Nav tabs className="nav-tabs-custom">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggle("1");
                }}
              >
                Monthly Spend
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggle("2");
                }}
              >
                Cost Distribution by Services
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggle("3");
                }}
              >
                VM Performance
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "4",
                })}
                onClick={() => {
                  toggle("4");
                }}
              >
                Storage Performance
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "5",
                })}
                onClick={() => {
                  toggle("5");
                }}
              >
                Backup Status
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "6",
                })}
                onClick={() => {
                  toggle("6");
                }}
              >
                Advisory Recommendations
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent key="tab" activeTab={activeTab} className="bg-white">
            <TabPane tabId="1">
              {loadTab1 && (
                <div className="iframe-container">
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "100",
                      background: "white",
                      display: "block",
                      width: "100%",
                      bottom: 0,
                      padding: "20px",
                    }}
                  ></div>
                  <Iframe
                    key="1"
                    url="https://app.powerbi.com/view?r=eyJrIjoiMjJhYmQwN2QtM2IwZC00N2M0LWJlZDktNzI4NWFiZDM2ZmY3IiwidCI6IjY0ZDJiYWUwLTVlYTUtNDc4NS1hNzQxLWI4YTAwNzFmMGExMSIsImMiOjF9"
                    frameborder="0"
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
            </TabPane>
            <TabPane tabId="2">
              {loadTab2 && (
                <div className="iframe-container">
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "100",
                      background: "white",
                      display: "block",
                      width: "100%",
                      bottom: 0,
                      padding: "20px",
                    }}
                  ></div>
                  <Iframe
                    key="2"
                    url="https://app.powerbi.com/view?r=eyJrIjoiNWNkZmZiZTgtOTBiNC00MDYwLWFhZTAtNTIzNGUxZTVjYWZmIiwidCI6IjY0ZDJiYWUwLTVlYTUtNDc4NS1hNzQxLWI4YTAwNzFmMGExMSIsImMiOjF9"
                    frameborder="0"
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
            </TabPane>
            <TabPane tabId="3">
              {loadTab3 && (
                <div className="iframe-container">
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "100",
                      background: "white",
                      display: "block",
                      width: "100%",
                      bottom: 0,
                      padding: "20px",
                    }}
                  ></div>
                  <Iframe
                    key="3"
                    url="https://app.powerbi.com/view?r=eyJrIjoiYjM2MjJhMDctZDEzOS00YTNkLWE1ZmEtNzM0ZmI3YzM2Y2M4IiwidCI6IjY0ZDJiYWUwLTVlYTUtNDc4NS1hNzQxLWI4YTAwNzFmMGExMSIsImMiOjF9"
                    frameborder="0"
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
            </TabPane>
            <TabPane tabId="4">
              {loadTab4 && (
                <div className="iframe-container">
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "100",
                      background: "white",
                      display: "block",
                      width: "100%",
                      bottom: 0,
                      padding: "20px",
                    }}
                  ></div>
                  <Iframe
                    key="4"
                    url="https://app.powerbi.com/view?r=eyJrIjoiYWVhNDg1YzUtMTczNS00YjNmLWI2MzItMmQyNDRmYjgyNzEwIiwidCI6IjY0ZDJiYWUwLTVlYTUtNDc4NS1hNzQxLWI4YTAwNzFmMGExMSIsImMiOjF9"
                    frameborder="0"
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
            </TabPane>
            <TabPane tabId="5">
              {loadTab5 && (
                <div className="iframe-container">
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "100",
                      background: "white",
                      display: "block",
                      width: "100%",
                      bottom: 0,
                      padding: "20px",
                    }}
                  ></div>
                  <Iframe
                    key="5"
                    url="https://app.powerbi.com/view?r=eyJrIjoiMWFjYzYzN2UtMDc1NS00NmZmLTg0ODQtZTQ0YTlhYjZhMTYxIiwidCI6IjY0ZDJiYWUwLTVlYTUtNDc4NS1hNzQxLWI4YTAwNzFmMGExMSIsImMiOjF9"
                    frameborder="0"
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
            </TabPane>
            <TabPane tabId="6">
              {loadTab6 && (
                <div className="iframe-container">
                  <div
                    style={{
                      position: "absolute",
                      zIndex: "100",
                      background: "white",
                      display: "block",
                      width: "100%",
                      bottom: 0,
                      padding: "20px",
                    }}
                  ></div>
                  <Iframe
                    key="6"
                    url="https://app.powerbi.com/view?r=eyJrIjoiOTIyMjRiMzEtMTFiNi00NmU4LWJjN2YtMTcxYTVmZmM0Mzg3IiwidCI6IjY0ZDJiYWUwLTVlYTUtNDc4NS1hNzQxLWI4YTAwNzFmMGExMSIsImMiOjF9"
                    frameborder="0"
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </React.Fragment>
  );
};