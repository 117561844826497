import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useContext, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import logoIconDark from "../../assets/images/favicon.png";
import logoDark from "../../assets/images/logo-dark-full.svg";
import logoIcon from "../../assets/images/logo-icon-white.svg";
import logoFull from "../../assets/images/logo-white-full.svg";
import { APP_CONFIG } from "../../config";
import { AuthContext } from "../../context";
import { crispStyle } from "../../helpers";
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from "../../store";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

const Header = (props) => {
	const { user, user_id, tenant_id, tenant, setAuthContext } = useContext(AuthContext);
	const [search, setsearch] = useState(false);
	// const [megaMenu, setmegaMenu] = useState(false);
	// const [socialDrp, setsocialDrp] = useState(false);

	const tenants = [...new Set(user?.permissions?.map((e) => e.tenant))];
	const [active_tenant, set_active_tenant] = useState({ label: tenant?.name + " Account", value: tenant_id });

	useEffect(() => {
		if (active_tenant?.value !== tenant_id)
			axios
				.post(`${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/switch-tenant`, {
					tenant_id: active_tenant?.value,
					user_id: user_id,
				})
				.then((response) => {
					if (response?.data?.success) {
						cogoToast.success("Switch Successful", { position: "top-right" });
						setAuthContext(response?.data?.data || {});
					} else cogoToast.error(response?.data?.message || "Error Processing Your Request !", { position: "top-right" });
				})
				.catch((error) => {
					console.error("error", error);
					cogoToast.error("Error Processing Your Request !", { position: "top-right" });
				});
	}, [active_tenant, setAuthContext, tenant_id, user_id]);

	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

	function toggleFullscreen() {
		if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	function tToggle() {
		props.toggleLeftmenu(!props.leftMenu);
		if (props.leftSideBarType === "default") {
			props.changeSidebarType("condensed", isMobile);
		} else if (props.leftSideBarType === "condensed") {
			props.changeSidebarType("default", isMobile);
		}
	}
	return (
		<React.Fragment>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box">
							<Link to="/" className="logo logo-dark">
								<span className="logo-sm">
									<img src={logoIconDark} alt="" height="25" />
								</span>
								<span className="logo-lg">
									<img src={logoDark} alt="" width="110px" />
								</span>
							</Link>

							<Link to="/" className="logo logo-light">
								<span className="logo-sm">
									<img src={logoIcon} alt="" height="25" />
								</span>
								<span className="logo-lg">
									<img src={logoFull} alt="" width="110px" />
								</span>
							</Link>
						</div>

						<button
							type="button"
							onClick={() => {
								tToggle();
							}}
							className="btn btn-sm px-3 font-size-16 header-item waves-effect"
							id="vertical-menu-btn"
						>
							<i className="fa fa-fw fa-bars"></i>
						</button>
						{/*}
            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
            */}
					</div>
					<div className="d-flex">
						<div className="dropdown d-inline-block d-lg-none ml-2">
							<button
								onClick={() => {
									setsearch(!search);
								}}
								type="button"
								className="btn header-item noti-icon waves-effect"
								id="page-header-search-dropdown"
							>
								<i className="mdi mdi-magnify"></i>
							</button>
							<div
								className={
									search
										? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
										: "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
								}
								aria-labelledby="page-header-search-dropdown"
							>
								<form className="p-3">
									<div className="form-group m-0">
										<div className="input-group">
											<input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
											<div className="input-group-append">
												<button className="btn btn-primary" type="submit">
													<i className="mdi mdi-magnify"></i>
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						{tenants.length > 1 && (
							<div className="dropdown d-none d-lg-inline-block ml-1" style={{ width: 400, marginTop: 20 }}>
								<Select
									styles={crispStyle}
									options={[...tenants.map((t) => ({ label: `${t.name} Account`, value: t.id }))]}
									value={active_tenant}
									onChange={set_active_tenant}
								/>
							</div>
						)}
						<div className="dropdown d-none d-lg-inline-block ml-1">
							<button
								type="button"
								onClick={() => {
									toggleFullscreen();
								}}
								className="btn header-item noti-icon waves-effect"
								data-toggle="fullscreen"
							>
								<i className="bx bx-fullscreen"></i>
							</button>
						</div>
						{/*}
            <NotificationDropdown />
              */}

						<ProfileMenu />
					</div>
				</div>
			</header>
		</React.Fragment>
	);
};
const mapStatetoProps = (state) => {
	const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
	return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
	showRightSidebarAction,
	toggleLeftmenu,
	changeSidebarType,
})(withNamespaces()(Header));
