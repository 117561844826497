import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { postFakeRegister, postJwtRegister } from "../../../helpers/fakebackend_helper";
//Include Both Helper File with needed methods
import { registerUserFailed, registerUserSuccessful } from "./actions";
//Account Redux states
import { REGISTER_USER } from "./actionTypes";

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
	try {
		if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
		} else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
			const response = yield call(postJwtRegister, "/post-jwt-register", user);
			yield put(registerUserSuccessful(response));
		} else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
			const response = yield call(postFakeRegister, "/post-fake-register", user);
			yield put(registerUserSuccessful(response));
		}
	} catch (error) {
		yield put(registerUserFailed(error));
	}
}

export function* watchUserRegister() {
	yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
	yield all([fork(watchUserRegister)]);
}

export default accountSaga;
