import { useState } from "react";
import { validateEmail } from "../helpers";

export function useFormValidation(initialState, formState = undefined) {
	const [state, setState] = useState(initialState);

	return [
		state,
		(e) => {
			switch (e.target.name) {
				case "name":
					if (!e.target.value) {
						setState({
							...state,
							[e.target.name]: "Please fill in your name",
						});
					} else {
						setState({
							...state,
							[e.target.name]: "",
						});
					}
					break;
				case "email":
					if (!e.target.value) {
						setState({
							...state,
							[e.target.name]: "Please enter an email address",
						});
					} else if (e.target.value && !validateEmail(e.target.value)) {
						setState({
							...state,
							[e.target.name]: "Please enter a valid email address",
						});
					} else {
						setState({
							...state,
							[e.target.name]: "",
						});
					}
					break;
				case "password":
					if (!e.target.value) {
						setState({
							...state,
							[e.target.name]: "Kindly enter a password",
						});
					} else if (e.target.value && e.target.value.length < 8) {
						setState({
							...state,
							[e.target.name]: "Password should be at least 8 characters long",
						});
					} else if (e.target.value && e.target.value.length >= 8 && !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(e.target.value)) {
						setState({
							...state,
							[e.target.name]: "Password should contain an uppercase, lowercase and a number",
						});
					} else {
						setState({
							...state,
							[e.target.name]: "",
						});
					}
					break;
				case "loginPassword":
					if (!e.target.value) {
						setState({
							...state,
							[e.target.name]: "Kindly enter a password",
						});
					} else {
						setState({
							...state,
							[e.target.name]: "",
						});
					}
					break;
				case "confirmPassword":
					if (!e.target.value) {
						setState({
							...state,
							[e.target.name]: "Please re-enter your password",
						});
					} else if (formState && formState.password && e.target.value !== formState.password) {
						setState({
							...state,
							[e.target.name]: "Password does not match",
						});
					} else {
						setState({
							...state,
							[e.target.name]: "",
						});
					}
					break;
				default:
					break;
			}
		},
	];
}
