import axios from "axios";
import cogoToast from "cogo-toast";
import parsePhoneNumber from "libphonenumber-js";
import queryString from "query-string";
import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import logoDark from "../../assets/images/logo-dark-full-211x50px.png";
import { APP_CONFIG, PATHS } from "../../config";
import { AuthContext } from "../../context";
import { validateEmail } from "../../helpers";
import CustomerInfo from "./CustomerInfo";
import PartnerInfo from "./PartnerInfo";

const Register = (props) => {
	const { name, email, auth } = queryString.parse(props.location.search);
	const { is_authenticated, setAuthContext } = useContext(AuthContext);

	const isCustomer = props.location.pathname === "/auth/register/customer";

	if(auth){
		setAuthContext(JSON.parse(auth));
	}

	const [formFields, setFormFields] = useState({
		name: name ? name : "",
		email: email ? email : "",
		phone: "",
		password: "",
		confirmPassword: "",
		isSubmitting: false,
		country: null,
	});

	const [formFieldErrors, setFormFieldErrors] = useState({
		name: "",
		email: "",
		phone: "",
		password: "",
		confirmPassword: "",
	});

	const [passwordVisibility, setPasswordVisibility] = useState({
		password: false,
		confirmPassword: false,
	});

	const [phoneFieldError, setPhoneFieldError] = useState("");

	const onBlur = (e) => {
		switch (e.target.name) {
			case "name":
				if (!e.target.value) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Please fill in your name",
					});
				} else {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "",
					});
				}
				break;
			case "email":
				if (!e.target.value) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Please enter an email address",
					});
				} else if (e.target.value && !validateEmail(e.target.value)) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Please enter a valid email address",
					});
				} else {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "",
					});
				}
				break;
			case "password":
				if (!e.target.value) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Kindly enter a password",
					});
				} else if (e.target.value && e.target.value.length < 8) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Password should be at least 8 characters long",
					});
				} else if (e.target.value && e.target.value.length >= 8 && !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(e.target.value)) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Password should contain an uppercase, lowercase and a number",
					});
				} else {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "",
					});
				}
				break;
			case "confirmPassword":
				if (!e.target.value) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Please re-enter your password",
					});
				} else if (formFields && formFields.password && e.target.value !== formFields.password) {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "Password does not match",
					});
				} else {
					setFormFieldErrors({
						...formFieldErrors,
						[e.target.name]: "",
					});
				}
				break;
			default:
				break;
		}
	};

	const onChange = (e) => {
		setFormFields({ ...formFields, [e.target.name]: e.target.value });
		onBlur(e);
	};

	const phoneInputOnBlur = (phone, country) => {
		if (!phone) {
			setPhoneFieldError("Please enter a phone number");
		} else if (
			phone &&
			typeof phone === "string" &&
			country &&
			!parsePhoneNumber(`+${phone}`, `${country.countryCode.toUpperCase()}`)?.isValid()
		) {
			setPhoneFieldError("Please enter a valid phone number");
		} else {
			setPhoneFieldError("");
		}
	};

	const validateFormData = (data) => {
		const { name, email, phone, password, confirmPassword, country } = data;
		let nameMsg, emailMsg, phoneMsg, passwordMsg, confirmPasswordMsg;

		if (!name) {
			nameMsg = "Please fill in your name";
		} else {
			nameMsg = "";
		}

		if (!email) {
			emailMsg = "Please enter an email address";
		} else if (email && !validateEmail(email)) {
			emailMsg = "Please enter a valid email address";
		} else {
			emailMsg = "";
		}

		if (!phone) {
			phoneMsg = "Please enter a phone number";
		} else if (
			(phone && !country) ||
			(phone && typeof phone === "string" && country && !parsePhoneNumber(`+${phone}`, `${country.countryCode.toUpperCase()}`)?.isValid())
		) {
			phoneMsg = "Please enter a valid phone number";
		} else {
			phoneMsg = "";
		}

		if (!password) {
			passwordMsg = "Please enter an email address";
		} else if (password && password.length < 8) {
			passwordMsg = "Password should be at least 8 characters long";
		} else if (password && password.length >= 8 && !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password)) {
			passwordMsg = "Password should contain an uppercase, lowercase and a number";
		} else {
			passwordMsg = "";
		}

		if (!confirmPassword) {
			confirmPasswordMsg = "Please re-enter your password";
		} else if (password && confirmPassword !== formFields.password) {
			confirmPasswordMsg = "Password does not match";
		} else {
			confirmPasswordMsg = "";
		}

		setFormFieldErrors({
			...formFieldErrors,
			name: nameMsg,
			email: emailMsg,
			password: passwordMsg,
			confirmPassword: confirmPasswordMsg,
		});

		setPhoneFieldError(phoneMsg);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		const { name, email, phone, password, confirmPassword } = formFields;

		if (
			[phoneFieldError, ...Object.values(formFieldErrors)].every((err) => {
				return err ? false : true;
			}) &&
			name &&
			email &&
			phone &&
			phone.length > 1 &&
			password &&
			confirmPassword === password
		) {
			setFormFields({ ...formFields, isSubmitting: true });
			try {
				const response = await axios({
					method: "POST",
					url: `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/register`,
					data: {
						name,
						email,
						password,
						phone: `+${phone}`,
						is_customer: isCustomer.toString(),
					},
				});
				if (response?.data?.success) setAuthContext(response?.data?.data || {});
				else throw response;
			} catch (err) {
				if (err?.response?.data) {
					switch (err.response.data.message) {
						case "Email ID already registered for existing user":
							setFormFieldErrors({
								...formFieldErrors,
								email: err.response.data.message,
							});
							break;

						case "Phone number is already in use":
							setPhoneFieldError(err.response.data.message);
							break;

						default:
							cogoToast.error(err.response.data.message, {
								position: "top-right",
							});
							break;
					}
				} else {
					cogoToast.error("Something went wrong. Please try again", {
						position: "top-right",
					});
				}
			}
			setFormFields({ ...formFields, isSubmitting: false });
		} else {
			validateFormData(formFields);
		}
	};

	const togglePasswordVisibility = (field) => {
		setPasswordVisibility({
			...passwordVisibility,
			[field]: !passwordVisibility[field],
		});
	};

	if (is_authenticated) return <Redirect to={PATHS.authRedirect} />;

	return (
		<>
			<main className="main auth register-page">
				<div className="left-pane-white">{isCustomer ? <CustomerInfo type="CustomerSignup" /> : <PartnerInfo type="PartnerSignup" />}</div>
				<div className="right-pane">
					<div className="d-flex flex-column justify-content-center h-100">
						<img src={logoDark} alt="FlipServe logo" className="d-none right-pane-logo align-self-center" />

						<div>
							<h3 className="text-dark display-4 mb-3 font-weight-bold">{isCustomer ? "Sign up today" : "Join us today"}</h3>
							<form className="" onSubmit={onSubmit}>
								<div className={formFieldErrors.name ? "form-group has-error" : "form-group"}>
									<label htmlFor="name">Full Name</label>
									<input
										type="text"
										name="name"
										id="name"
										className="form-control"
										placeholder="John Doe"
										value={formFields.name}
										onChange={onChange}
										onBlur={onBlur}
									/>
									{formFieldErrors.name && <p className="form-validation-message">{formFieldErrors.name}</p>}
								</div>
								<div className={formFieldErrors.email ? "form-group has-error" : "form-group"}>
									<label htmlFor="email">Email ID</label>
									<input
										type="email"
										name="email"
										id="email"
										className="form-control"
										placeholder="name@domain.com"
										value={formFields.email}
										onChange={onChange}
										onBlur={onBlur}
									/>
									{formFieldErrors.email && <p className="form-validation-message">{formFieldErrors.email}</p>}
								</div>
								<div className={phoneFieldError ? "form-group has-error" : "form-group"}>
									<label htmlFor="phone">Phone Number</label>
									<PhoneInput
										inputClass="w-100 phone-input-control"
										country={"us"}
										name="phone"
										value={formFields.phone}
										onChange={(phone, country) => {
											setFormFields({ ...formFields, phone, country });
											phoneInputOnBlur(phone, country);
										}}
										onBlur={(phone, country) => {
											phoneInputOnBlur(phone.target.value.replace(/^\D+/g, ""), country);
										}}
									/>
									{phoneFieldError && <p className="form-validation-message">{phoneFieldError}</p>}
								</div>
								<div className={formFieldErrors.password ? "form-group has-error" : "form-group"}>
									<label htmlFor="password">Password</label>
									<div className="input-group">
										<input
											type={passwordVisibility.password ? "text" : "password"}
											name="password"
											id="password"
											className="form-control"
											placeholder=""
											value={formFields.password}
											onChange={onChange}
											onBlur={onBlur}
										/>
										<div className="input-group-append" onClick={() => togglePasswordVisibility("password")}>
											<i
												className={passwordVisibility.password ? "input-group-text fa fa-eye-slash" : "input-group-text fa fa-eye"}
												aria-hidden="true"
											></i>
										</div>
									</div>
									{formFieldErrors.password && <p className="form-validation-message">{formFieldErrors.password}</p>}
								</div>
								<div className={formFieldErrors.confirmPassword ? "form-group mb-2 has-error" : "form-group mb-2"}>
									<label htmlFor="confirmPassword">Confirm Password</label>
									<div className="input-group">
										<input
											type={passwordVisibility.confirmPassword ? "text" : "password"}
											name="confirmPassword"
											id="confirmPassword"
											className="form-control"
											placeholder=""
											value={formFields.confirmPassword}
											onChange={onChange}
											onBlur={onBlur}
										/>
										<div className="input-group-append" onClick={() => togglePasswordVisibility("confirmPassword")}>
											<i
												className={passwordVisibility.confirmPassword ? "input-group-text fa fa-eye-slash" : "input-group-text fa fa-eye"}
												aria-hidden="true"
											></i>
										</div>
									</div>
									{formFieldErrors.confirmPassword && <p className="form-validation-message">{formFieldErrors.confirmPassword}</p>}
								</div>
								<p className="legal-notice mb-4">
									By signing up, you agree to the <a  href="https://news.flipserve.com/terms">Terms</a> &amp; <a href="https://news.flipserve.com/privacy-policy">Privacy Policy</a>
								</p>
								<button type="submit" className="btn btn-block btn-primary" onClick={onSubmit} disabled={formFields.isSubmitting}>
									{formFields.isSubmitting ? <Spinner size="sm" role="status" /> : <span>Register</span>}
								</button>
							</form>
						</div>

						<div className="mt-3">
							<p className="text-center">
								Already have an account?{" "}
								<span>
									<Link to="/auth/login" className="text-primary ">
										Log In
									</Link>
								</span>
							</p>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default Register;
