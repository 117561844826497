import React from "react";
import { usePagination, useTable } from "react-table";
import { Spinner, Table } from "reactstrap";
import nodataimg from "../../assets/images/icons/noservers.svg";

const defaultPropGetter = () => ({});

function ReactTable({
	columns,
	data,
	pagination,
	loading,
	norecordsimg,
	norecordsmsg,
	getHeaderProps = defaultPropGetter,
	getColumnProps = defaultPropGetter,
	getRowProps = defaultPropGetter,
	getCellProps = defaultPropGetter,
}) {
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page, // Instead of using 'rows', we'll use page,
		// which has only the rows for the active page

		// The rest of these things are super handy, too ;)
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 100 },
		},
		usePagination
	);

	function getTableBody(page) {
		if (loading) {
			return (
				<tbody>
					<tr>
						<td colSpan={columns.length} className="text-center" style={{ height: "200px" }}>
							<Spinner />
						</td>
					</tr>
				</tbody>
			);
		}
		if (page && page.length > 0) {
			// For single row it returns "No record"  1->0
			return (
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td
											// Return an array of prop objects and react-table will merge them appropriately
											{...cell.getCellProps([
												{
													className: cell.column.className,
													style: cell.column.style,
												},
												getColumnProps(cell.column),
												getCellProps(cell),
											])}
										>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			);
		} else {
			return (
				<tbody>
					<tr>
						<td colSpan={columns.length} className="text-center">
							{norecordsimg && (
								<img
									src={norecordsimg}
									alt=""
									style={{
										height: "100px",
										marginTop: "100px",
										marginBottom: "25px",
									}}
								/>
							)}
							{!norecordsimg && (
								<img
									src={nodataimg}
									alt=""
									style={{
										height: "100px",
										marginTop: "100px",
										marginBottom: "25px",
									}}
								/>
							)}

							<br />
							{norecordsmsg && norecordsmsg}
							{!norecordsmsg && (
								<p className="text-muted" style={{ marginBottom: "25px" }}>
									No records found
								</p>
							)}

							<br />
						</td>
					</tr>
				</tbody>
			);
		}
	}

	// Render the UI for your table
	return (
		<React.Fragment>
			<div className="table-responsive">
				<Table className="table table-centered table-sm" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th
										// Return an array of prop objects and react-table will merge them appropriately
										{...column.getHeaderProps([
											{
												className: column.className,
												style: column.style,
											},
											getColumnProps(column),
											getHeaderProps(column),
										])}
									>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					{getTableBody(page)}
				</Table>
				{/*
          Pagination can be built however you'd like.
          This is just a very basic UI implementation:
        */}
			</div>
			{pagination && (
				<div className="pagination">
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						{"<<"}
					</button>{" "}
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						{"<"}
					</button>{" "}
					<button onClick={() => nextPage()} disabled={!canNextPage}>
						{">"}
					</button>{" "}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						{">>"}
					</button>{" "}
					<span>
						Page{" "}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{" "}
					</span>
					<span>
						| Go to page:{" "}
						<input
							type="number"
							defaultValue={pageIndex + 1}
							onChange={(e) => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0;
								gotoPage(page);
							}}
							style={{ width: "100px" }}
						/>
					</span>{" "}
					<select
						value={pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value));
						}}
					>
						{[100, 150, 200].map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</select>
				</div>
			)}
		</React.Fragment>
	);
}

export default ReactTable;
