import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import GlobalProvider from "./context";
import "./i18n";
import "./index.css";
import "./responsive.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

const Root = () => {
	return (
		<GlobalProvider>
			<Provider store={store}>
				<App />
			</Provider>
		</GlobalProvider>
	);
};

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.unregister();
