// Queries can have multiple first-level fields to retrieve

export const Q_ENTITY_COUNT = (entity) =>
	`query ${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { count } } }`;
export const Q_ENTITY_AGGREGATE = (entity, fields) =>
	`query ${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { ${fields} } } }`;
export const Q_ENTITY_DATA = (
	entity,
	fields
) => `query ${entity}($where: ${entity}_bool_exp, $limit: Int, $offset: Int, $order_by: [${entity}_order_by!], $distinct_on: [${entity}_select_column!]) {
		objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${fields} } }`;

export const Q_ENTITY_BY_PK = (entity, fields) => `query ${entity}_by_pk($id: uuid!) {object: ${entity}_by_pk(id:$id) { id ${fields} } }`;

export const Q_ENTITY_AGG = (entity, aggregate, field) =>
	`query ${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { ${aggregate} { ${field} } } } }`;

export const Q_ID_NAMES = (
	entity,
	fields
) => `query ${entity}($where: ${entity}_bool_exp, $limit: Int, $offset: Int, $order_by: [${entity}_order_by!]) {
	objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by) { id name ${fields} } }`;

export const Q_ENUMS = (entity) => `query ${entity}{objects: ${entity}{id: value value comment}}`;
export const Q_ID_NAME = (entity, fields) => `query ${entity}_by_pk($id:uuid!) {object: ${entity}_by_pk(id: $id){ id name ${fields}}}`;

export const Q_UNIQUE_NAME = (entity) =>
	`query ${entity}_aggregate($text:String!) {counts: ${entity}_aggregate(where:{name:{_ilike:$text}}) { aggregate {count} } }`;
export const Q_UNIQUE_EMAIL = (entity) =>
	`query users_aggregate($text:String!) {counts: users_aggregate(where:{email:{_ilike:$text}}) { aggregate {count} } }`;
