import { gql, useQuery, useSubscription } from "@apollo/client";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Q_ENTITY_AGG, Q_ENTITY_AGGREGATE, Q_ENTITY_BY_PK, Q_ENTITY_COUNT, Q_ENTITY_DATA } from "../gql/query";
import { S_ENTITY_AGGREGATE, S_ENTITY_BY_PK, S_ENTITY_COUNT, S_ENTITY_DATA } from "../gql/subscription";

export const useTotalSubscription = ({ entity = "users", where = {}, query }) => {
	const [total, setTotal] = useState(0);
	const { data, loading, error } = useSubscription(gql(query ?? S_ENTITY_COUNT(entity)), {
		variables: { where: where },
	});
	if (!loading && !error && data?.counts?.aggregate && data?.counts?.aggregate?.count !== total) setTotal(data?.counts?.aggregate?.count ?? 0);
	return total;
};

export const useTotalQuery = ({ entity = "users", where = {}, query }) => {
	const [total, setTotal] = useState(0);
	const { data, loading, error } = useQuery(gql(query ?? Q_ENTITY_COUNT(entity)), {
		variables: { where: where },
	});
	if (!loading && !error && data?.counts?.aggregate && data?.counts?.aggregate?.count !== total) setTotal(data?.counts?.aggregate?.count ?? 0);
	return total;
};

export const useAggregateSubscription = ({ entity, where = {}, query, fields }) => {
	const [aggregate, setAggregate] = useState({ sum: {}, avg: {}, count: 0 });
	const { data, loading, error } = useSubscription(gql(query ?? S_ENTITY_AGGREGATE(entity, fields)), {
		variables: { where },
	});
	if (!loading && !error && data?.counts?.aggregate && !_.isEqual(data?.counts?.aggregate, aggregate)) setAggregate(data?.counts?.aggregate);
	return aggregate;
};

export const useAggregateQuery = ({ entity, where = {}, query, fields }) => {
	const [aggregate, setAggregate] = useState({ sum: {}, avg: {}, count: 0 });
	const { data, loading, error } = useQuery(gql(query ?? Q_ENTITY_AGGREGATE(entity, fields)), {
		variables: { where },
	});
	if (!loading && !error && data?.counts?.aggregate && !_.isEqual(data?.counts?.aggregate, aggregate)) setAggregate(data?.counts?.aggregate);
	return aggregate;
};

export const useAggQuery = ({ entity, where = {}, query, field, aggregate = "sum" }) => {
	const [value, setValue] = useState(0);
	const { data, loading, error } = useQuery(gql(query ?? Q_ENTITY_AGG(entity, aggregate, field)), {
		variables: { where },
	});
	if (
		!loading &&
		!error &&
		data?.counts?.aggregate &&
		data?.counts?.aggregate[aggregate][field] &&
		!_.isEqual(data?.counts?.aggregate[aggregate][field], value)
	)
		setValue(data?.counts?.aggregate[aggregate][field]);
	return value;
};

export const usePaginatedQuery = ({
	entity = "users",
	query,
	fields,
	no_search = {},
	search_on = {},
	order_by = {},
	hideScores = false,
	skipCache,
	...rest
}) => {
	const [objects, setObjects] = useState([]);
	const [limit, setLimit] = useState(rest.limit || 10);
	const [offset, setOffset] = useState(rest.offset || 0);

	const total = useTotalSubscription({ entity, where: { ...no_search } });
	const count = useTotalQuery({ entity, where: { ...no_search, ...search_on } });
	let query_variables = { variables: { where: { ...no_search, ...search_on }, limit, offset, order_by } };
	if (skipCache) query_variables.skipCache = true;
	const { data, loading, refetch, error } = useQuery(gql(query ?? Q_ENTITY_DATA(entity, String(fields))), query_variables);

	const pages = Math.ceil(count / limit);
	const page = Math.floor(offset / limit) + 1;
	if (offset !== (page - 1) * limit) setOffset((page - 1) * limit);
	if (limit > total && total > 10) setLimit(total);

	useEffect(() => {
		if (!loading && !error && data?.objects) setObjects(data.objects.map((o) => ({ ...o, refetch })));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, loading, error]);

	useEffect(() => {
		if (count < offset) setOffset(0);
	}, [offset, count]);

	useEffect(() => {
		if (!loading && !error) refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, total, error]);

	return {
		objects,
		loading,
		error,
		refetch,
		setLimit,
		setOffset,
		limit,
		offset,
		count,
		pages,
		page,
		total,
	};
};

export const useObjectsQuery = ({
	entity = "users",
	fields,
	no_search = {},
	search_on = {},
	order_by = {},
	to_enum = false,
	enum_value = "id",
	enum_comment = "name",
	skipCache,
	query,
	limit,
}) => {
	const [objects, setObjects] = useState([]);
	const total = useTotalSubscription({ entity, where: { ...no_search } });
	const count = useTotalQuery({ entity, where: { ...no_search, ...search_on } });
	let query_variables = { variables: { where: { ...no_search, ...search_on }, order_by } };
	if (skipCache) query_variables.skipCache = true;
	if (limit) query_variables.variables.limit = limit;
	const { data, error, loading, refetch } = useQuery(gql(query ?? Q_ENTITY_DATA(entity, fields)), query_variables);

	useEffect(() => {
		if (!loading && !error && data?.objects) {
			if (to_enum) {
				let list = [];
				data.objects.map((o) => {
					list.push({ value: _.get(o, enum_value), comment: _.get(o, enum_comment) });
					return o;
				});
				setObjects(list);
			} else {
				setObjects(data.objects.map((o) => ({ ...o, refetch })));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, error, loading]);

	useEffect(() => {
		if (!loading && !error && total !== count) refetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, total, count, error]);
	return {
		objects,
		error,
		loading,
		refetch,
		total: count,
	};
};

export const useObjectByPkQuery = ({ id, entity = "users", fields, query }) => {
	const [object, setObject] = useState(null);
	const { data, error, loading, refetch } = useQuery(gql(query ?? Q_ENTITY_BY_PK(entity, fields)), { variables: { id } });

	useEffect(() => {
		if (!loading && !error && data && data.object && !_.isEqual(data.object, object)) setObject(data.object);
	}, [data, error, loading, object]);

	return { object, error, loading, refetch };
};

export const useObjectByPkSubscription = ({ id, entity = "users", fields, query }) => {
	const [object, setObject] = useState(null);
	const { data, error, loading } = useSubscription(gql(query ?? S_ENTITY_BY_PK(entity, fields)), { variables: { id } });

	useEffect(() => {
		if (!loading && !error && data && data.object && !_.isEqual(data.object, object)) setObject(data.object);
	}, [data, error, loading, object]);

	return { object, error, loading };
};

export const useObjectQuery = ({ entity = "users", fields, query, where = {} }) => {
	const [object, setObject] = useState(null);
	const { data, error, loading } = useQuery(gql(query ?? Q_ENTITY_DATA(entity, fields)), { variables: { where: where } });

	useEffect(() => {
		if (!loading && !error && data.objects && data.objects[0]) setObject(data.objects[0]);
	}, [data, error, loading]);

	return object;
};

export const useObjectSubscription = ({ entity = "users", fields, query, where = {} }) => {
	const [object, setObject] = useState(null);
	const { data, error, loading } = useSubscription(gql(query ?? S_ENTITY_DATA(entity, fields)), { variables: { where: where } });

	useEffect(() => {
		if (!loading && !error && data.objects && data.objects[0]) setObject(data.objects[0]);
	}, [data, error, loading]);

	return object;
};
