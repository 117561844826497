import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Alert, Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import Container from "reactstrap/lib/Container";
import { AuthContext, GeneralContext } from "../../context";
import { crispStyle } from "../../helpers";
import { SelectCSP } from "./selectCSP";

export const PageContainer = ({
	icon,
	title,
	// subtitle,
	date_filter = false,
	hideplans = false,
	hidesubscriptions_filter = false,
	hideplans_filter = false,
	hide_csp_filter = false,
	hide_comingsoon_note = false,
	component = () => {},
}) => {
	const { tenant, role } = useContext(AuthContext);
	const { price_plans, enum_csp_types_map } = useContext(GeneralContext);

	const [csp_type, set_csp_type] = useState("azure");
	const [csp_tenant_id, set_csp_tenant_id] = useState("");
	const [service_subscriptions, set_service_subscriptions] = useState([]);
	const [active_date, set_active_date] = useState(moment().format("YYYY-MM-DD"));
	const [active_service_subscription, set_active_service_subscription] = useState({ label: "All Subscriptions", value: 0 });
	const [active_price_plan, set_active_price_plan] = useState({ label: "All Plans", value: 0, color: "#000000" });
	useEffect(() => {
		const active_service = tenant?.services?.find((x) => x.csp_type === csp_type);
		if (active_service) {
			if (active_service?.csp_tenant_id) set_csp_tenant_id(active_service.csp_tenant_id);
			if (active_service?.service_subscriptions) set_service_subscriptions(active_service.service_subscriptions);
		} else {
			set_service_subscriptions([]);
			set_csp_tenant_id("");
		}
	}, [csp_type, tenant]);

	const [open, set_open] = useState(false);
	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col>
							<div className="d-flex align-items-center">
								<div className="avatar-xs mr-3">
									<span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
										<i className={icon}></i>
									</span>
								</div>
								<h5 className="mb-0">
									{title}
									{/*}
									{subtitle && (
										<>
											<br />
											<small className="text-muted mb-2">{subtitle}</small>
										</>
									)}
									*/}
									{csp_tenant_id && !hide_csp_filter && (
										<>
											<br />
											<small className="text-muted mb-2">
												<span>{enum_csp_types_map[csp_type]} Tenant ID : </span>
												<span className="text-muted">{csp_tenant_id}</span>
											</small>
										</>
									)}
								</h5>
							</div>
						</Col>
						{csp_type && set_csp_type && !hide_csp_filter && (
							<Col>
								<SelectCSP value={csp_type} onChange={set_csp_type} rightAligned={true} />
							</Col>
						)}
					</Row>
					<hr />

					{csp_tenant_id ? (
						<>
							<Row className="mt-2">
								{!hidesubscriptions_filter && (
									<Col md={3}>
										{/* <div className="text-muted text-point-nine mb-2">Subscription</div> */}
										<Select
											menuPosition={"fixed"}
											styles={crispStyle}
											options={[
												...service_subscriptions.map((ss) => ({ label: `${ss.name} ( ${ss.csp_subscription_id} )`, value: ss.id })),
												{ label: "All Subscriptions", value: 0 },
											]}
											value={active_service_subscription}
											onChange={set_active_service_subscription}
										/>
									</Col>
								)}
								{!hideplans_filter && (
									<Col md={3}>
										{/* <div className="text-muted text-point-nine mb-2">Price Plan</div> */}

										<Select
											menuPosition={"fixed"}
											styles={crispStyle}
											options={[
												...price_plans.map((pp) => ({
													label: `${pp.name} ( ${pp.cost ? `$${pp.cost.toFixed(2)}` : "Free"} ) - ${pp.support}`,
													value: pp.id,
													color: pp.color,
												})),
												{ label: "All Plans", value: 0, color: "#000000" },
											]}
											value={active_price_plan}
											onChange={set_active_price_plan}
										/>
									</Col>
								)}

								<Col md={2}>
									{date_filter && (
										<>
											{/* <div className="text-muted text-point-nine mb-2">Date</div> */}
											<input
												className="form-control form-control-sm "
												type="date"
												id="example-date-input"
												value={active_date}
												onChange={(e) => set_active_date(e.target.value)}
											/>
										</>
									)}
								</Col>
								{!hideplans && (
									<Col>
										<span className="text-primary float-right" style={{ cursor: "pointer" }} onClick={() => set_open(!open)}>
											View all Plan Features
										</span>
									</Col>
								)}
							</Row>
							{/* {!hideplans && (
								<>
									<Row className="mt-2 mb-0 text-point-nine ">
										<Col>
											<span className="text-primary float-right" style={{ cursor: "pointer" }} onClick={() => set_open(!open)}>
												{" "}
												View all Plan Features{" "}
											</span>
											<span className="p-1">
												{price_plans.map((pp, i) => (
													<span key={i} className="mr-3  text-muted">
														<i className="mdi mdi-checkbox-blank-circle" style={{ color: pp.color }}></i>{" "}
														{`${pp.name} ( ${pp.cost ? `$${pp.cost.toFixed(2)}` : "Free"} ) - ${pp.support}`}
													</span>
												))}
											</span>
										</Col>
									</Row>
								</>
							)} */}
							<br />
							{component({
								csp_tenant_id,
								csp_type,
								date: date_filter ? active_date : null,
								price_plan_id: active_price_plan.value,
								service_subscription_id: active_service_subscription.value,
							})}
						</>
					) 
					/*
					: csp_type === "azure" ? (
						role === "owner" ? (
							<>
								<Link to="/setup" className="btn btn-sm btn-primary mr-3">
									<span>Onboard Microsoft Azure</span>
								</Link>
								<Link to="/quote" className="btn btn-sm btn-primary">
									<span>Setup a New Cloud Account</span>
								</Link>
							</>
						) : (
							<span />
						)
					) 
					*/
					
					: hide_comingsoon_note === false ? (
						<Alert color="info" className="mb-2 mt-5" role="alert">
							<i className="mdi mdi-alert-circle-outline mr-2"></i> Thank you for your interest. We are working on our integration with{" "}
							{enum_csp_types_map[csp_type]} and will be releasing this feature shortly.
						</Alert>
					)
				: (
						<></>
					)
				}
					<Modal isOpen={open} size="xl">
						<ModalHeader className="bg-light" toggle={() => set_open(!open)}>
							<div className="d-flex align-items-center ">
								<div className="avatar-xs mr-3">
									<span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
										<i className="mdi mdi-shield-lock-outline"></i>
									</span>
								</div>
								<h5 className="mb-0">Plan Features</h5>
							</div>
						</ModalHeader>
						<ModalBody style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}>
							<Row className="mt-2">
								<Col lg="12">
									<Accordion preExpanded={["item_1"]}>
										<AccordionItem uuid="item_1">
											<AccordionItemHeading>
												<AccordionItemButton>Daily Support and Management</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<div className="table-responsive">
													<Table className="table-centered mb-0 table-sm">
														<thead>
															<tr>
																<th className="w-25 bg-white">Feature</th>
																<th className="w-25 text-center bg-white"> Silver </th>
																<th className="w-25 text-center bg-white">Gold </th>
																<th className="w-25 text-center bg-white">Platinum</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="w-25 bg-white">Monitoring</td>
																<td className="w-25 text-center bg-white"> Basic </td>
																<td className="w-25 text-center bg-white">Advanced </td>
																<td className="w-25 text-center bg-white">Customized</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Support via Chat</td>
																<td className="w-25 text-center bg-white">
																	{" "}
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Remote Management & Troubleshooting</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Cloud resource management </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">VM level support </td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"></td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Managed Antivirus</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Remote support and troubleshooting</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Realtime health and custom dashboards</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"></td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading>
												<AccordionItemButton>Monitoring</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<div className="table-responsive">
													<Table className="table-centered mb-0 table-sm">
														<thead>
															<tr>
																<th className="w-25 bg-white">Feature</th>
																<th className="w-25 text-center bg-white"> Silver </th>
																<th className="w-25 text-center bg-white">Gold </th>
																<th className="w-25 text-center bg-white">Platinum</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="w-25 bg-white">Server Availability</td>
																<td className="w-25 text-center bg-white">
																	{" "}
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">vCPU Usage</td>
																<td className="w-25 text-center bg-white">
																	{" "}
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">RAM Usage</td>
																<td className="w-25 text-center bg-white">
																	{" "}
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Disk IOPS</td>
																<td className="w-25 text-center bg-white">
																	{" "}
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">OS Event Logs</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Filesystem usage alerts</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Critical OS Related Service Monitoring</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"></td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Custom Monitoring at Application Level</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"></td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">CSP Related Events such as Region Failures</td>
																<td className="w-25 text-center bg-white">
																	{" "}
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</AccordionItemPanel>
										</AccordionItem>

										<AccordionItem>
											<AccordionItemHeading>
												<AccordionItemButton>Identity and Access management</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<div className="table-responsive">
													<Table className="table-centered mb-0 table-sm">
														<thead>
															<tr>
																<th className="w-25 bg-white">Feature</th>
																<th className="w-25 text-center bg-white"> Silver </th>
																<th className="w-25 text-center bg-white">Gold </th>
																<th className="w-25 text-center bg-white">Platinum</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="w-25 bg-white">Administration of Azure IAM</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Configuration and management of Azure Key Vault</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>

															<tr>
																<td className="w-25 bg-white">RBAC and subscription management</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>

															<tr>
																<td className="w-25 bg-white">Azure Active Directory support</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"></td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading>
												<AccordionItemButton>Cost management and resource optimization</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<div className="table-responsive">
													<Table className="table-centered mb-0 table-sm">
														<thead>
															<tr>
																<th className="w-25 bg-white">Feature</th>
																<th className="w-25 text-center bg-white"> Silver </th>
																<th className="w-25 text-center bg-white">Gold </th>
																<th className="w-25 text-center bg-white">Platinum</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="w-25 bg-white">Cloud cost budgeting, alerting and forecasting</td>
																<td className="w-25 text-center bg-white">
																	{" "}
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading>
												<AccordionItemButton>High Availability and Disaster Recovery</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<div className="table-responsive">
													<Table className="table-centered mb-0 table-sm">
														<thead>
															<tr>
																<th className="w-25 bg-white">Feature</th>
																<th className="w-25 text-center bg-white"> Silver </th>
																<th className="w-25 text-center bg-white">Gold </th>
																<th className="w-25 text-center bg-white">Platinum</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="w-25 bg-white">Fully Managed HA based solution</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Fully managed ASR based Azure DR </td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"></td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</AccordionItemPanel>
										</AccordionItem>
										<AccordionItem>
											<AccordionItemHeading>
												<AccordionItemButton>Security</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<div className="table-responsive">
													<Table className="table-centered mb-0 table-sm">
														<thead>
															<tr>
																<th className="w-25 bg-white">Feature</th>
																<th className="w-25 text-center bg-white"> Silver </th>
																<th className="w-25 text-center bg-white">Gold </th>
																<th className="w-25 text-center bg-white">Platinum</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td className="w-25 bg-white">Advanced threat analytics</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
															<tr>
																<td className="w-25 bg-white">Intrusion detection and remediation</td>
																<td className="w-25 text-center bg-white"> </td>
																<td className="w-25 text-center bg-white"></td>
																<td className="w-25 text-center bg-white">
																	<i className="mdi mdi-checkbox-blank-circle text-info font-size-14"></i>{" "}
																</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</AccordionItemPanel>
										</AccordionItem>
									</Accordion>
									<br />
								</Col>
							</Row>
						</ModalBody>
					</Modal>
				</Container>
			</div>
		</React.Fragment>
	);
};
