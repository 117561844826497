import { CardElement } from "@stripe/react-stripe-js";
import _ from "lodash";
import React, { forwardRef, useEffect, useRef, useState } from "react";

export const useLocalStorage = (key, initialValue) => {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue
			console.error(error);
			return initialValue;
		}
	});

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to window.localStorage.
	const setValue = (value) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.error(error);
		}
	};

	return [storedValue, setValue];
};

export const uuidv4 = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const formatOrderBy = (orderBy, columnMap) => {
	let order_by = {};
	if (orderBy?.id)
		_.set(
			order_by,
			orderBy.id.replace(".aggregate.", ".") + (columnMap && columnMap[orderBy.id] && columnMap[orderBy.id].type === "uuid" ? ".name" : ""),
			orderBy.desc ? "desc_nulls_last" : "asc_nulls_last"
		);

	return order_by;
};

export const getParams = (url) => {
	// we'll store the parameters here
	let pathObj = {};

	const pathString = url ? url.split("?")[0] : window.location.pathname.slice(1);
	pathObj.pathString = pathString;
	// if path string exists
	if (pathString) {
		// split our query string into its component parts
		const args = pathString.split("/");
		pathObj.args = args;
		for (let i = 0; i < args.length; i++) {
			pathObj["arg_" + i] = args[i];
		}
	}

	// stuff after # is not part of query string, so get rid of it
	const queryString = (url ? url.split("?")[1] : window.location.search.slice(1)).split("#")[0];
	if (queryString) {
		pathObj.queryString = queryString;
		pathObj.params = queryStringToJSON(queryString);
	}
	if (!_.isEmpty(pathObj.params)) pathObj = { ...pathObj, ...pathObj.params };
	return pathObj;
};
export const queryStringToJSON = (queryString) => {
	var pairs = queryString.split("&");
	var result = {};
	pairs.forEach(function (p) {
		var pair = p.split("=");
		var key = pair[0];
		var value = decodeURIComponent(pair[1] || "");
		if (key === "filter_by" && value) value = JSON.parse(value);

		if (result[key]) {
			if (Object.prototype.toString.call(result[key]) === "[object Array]") {
				result[key].push(value);
			} else {
				result[key] = [result[key], value];
			}
		} else {
			result[key] = value;
		}
	});

	return JSON.parse(JSON.stringify(result));
};

// export const getFilterBy = (filters) => {
// 	let newObject = {};
// 	for (const [key, value] of Object.entries(filters)) {
// 		if (value && value.id && value.name) {
// 			newObject[key + "_id"] = { _eq: value.id };
// 		} else if (_.isArray(value) && !_.isEmpty(value) && value[0].id) {
// 			if (!newObject[key + "_id"]) newObject[key + "_id"] = { _in: _.uniq(value.map((e) => e.id)) };
// 			else newObject[key + "_id"]._in.push(...value.map((e) => e.id));

// 			value.map((v) => {
// 				if (v?.location?.id) {
// 					if (!newObject["location_id"]) newObject["location_id"] = { _in: [v.location.id] };
// 					else newObject["location_id"]._in.push(v.location.id);
// 				}
// 				if (v?.team?.id) {
// 					if (!newObject["team_id"]) newObject["team_id"] = { _in: [v.team.id] };
// 					else newObject["team_id"]._in.push(v.team.id);
// 				}
// 				if (v?.role?.id) {
// 					if (!newObject["role_id"]) newObject["role_id"] = { _in: [v.role.id] };
// 					else newObject["role_id"]._in.push(v.role.id);
// 				}
// 				if (v?.designation?.id) {
// 					if (!newObject["designation_id"]) newObject["designation_id"] = { _in: [v.designation.id] };
// 					else newObject["designation_id"]._in.push(v.designation.id);
// 				}
// 				return v;
// 			});
// 		} else if (_.isArray(value)) {
// 			if (!_.isEmpty(value)) newObject[key] = { _in: value };
// 		} else newObject[key] = { _eq: value };
// 	}
// 	return newObject;
// };

export const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return <input key={Math.random()} type="checkbox" ref={resolvedRef} {...rest} />;
});

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#c4f0ff",
			color: "#495057",
			fontWeight: 400,
			fontSize: ".9rem",
			fontSmoothing: "antialiased",
			":-webkit-autofill": {
				color: "#495057",
			},
			"::placeholder": {
				color: "#495057",
			},
		},
		invalid: {
			iconColor: "#f46a6a",
			color: "#f46a6a",
		},
	},
};

export const CardField = ({ onChange }) => (
	<div className="FormRow">
		<CardElement options={CARD_OPTIONS} onChange={onChange} />
	</div>
);

export const SubmitButton = ({ processing, error, children, disabled }) => (
	<button
		//className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
		className="btn btn-success btn-block "
		type="submit"
		disabled={processing || disabled}
	>
		{processing ? "Processing..." : children}
	</button>
);

export const dot = (color = "#ccc") => ({
	alignItems: "center",
	display: "flex",

	":before": {
		backgroundColor: color,
		borderRadius: 10,
		content: '" "',
		display: "block",
		marginRight: 8,
		height: 10,
		width: 10,
	},
});

export const getUrlPath = (key, value, url) => {
	let { args } = getParams(url);
	if (value) args[key] = value;
	else args = args.slice(0, key);
	return "/" + args.join("/");
};

export const crispStyle = {
	control: (prop) => ({
		...prop,
		padding: "0 3px 0 8px",
		borderRadius: "3px",
		minHeight: "25px",
		fontSize: "12px",
		borderColor: "rgba(72, 94, 144, 0.16)",
		"&:hover": {
			borderColor: "rgba(72, 94, 144, 0.16)",
		},
	}),
	input: (prop) => ({
		...prop,
		margin: 0,
		padding: 0,
	}),
	valueContainer: (prop) => ({
		...prop,
		margin: 0,
		padding: 0,
	}),
	singleValue: (styles, { data }) => ({
		...styles,
		margin: 0,
		padding: 0,
		...(data.color ? dot(data.color) : {}),
	}),

	dropdownIndicator: (prop) => ({
		...prop,
		margin: 0,
		padding: "0 3px 0 0",
	}),
	indicatorsContainer: (prop) => ({
		...prop,
		margin: 0,
		padding: 0,
	}),
	clearIndicator: (prop) => ({
		...prop,
		margin: 0,
		padding: 0,
	}),
	indicatorSeparator: (prop) => ({
		...prop,
		margin: "3px",
		padding: 0,
	}),
	noOptionsMessage: (prop) => ({
		...prop,
		padding: 0,
		fontSize: "12px",
	}),
	option: (prop) => ({
		...prop,
		padding: "8px",
		fontSize: "12px",
	}),
	menu: (prop) => ({
		...prop,
		borderRadius: "3px",
	}),
	menuPortal: (base) => ({ ...base, zIndex: 9999, top: base.top - 5 }),
};
