import React from "react";
import "./Icon.css";

const Icon = ({ type, ...rest }) => {
	return (
		<>
			{type === "cloud-connection" && (
				<svg xmlns="http://www.w3.org/2000/svg" width="52.33" height="48.514" viewBox="0 0 52.33 48.514" {...rest}>
					<path
						d="M33.239,94.853H29V89.887h.7A1.5,1.5,0,0,0,31.206,88.4V85.489A1.5,1.5,0,0,0,29.7,84H26.741a1.5,1.5,0,0,0-1.507,1.485V88.4a1.5,1.5,0,0,0,1.507,1.485h.7v4.966H17.436V92.281h.7A1.5,1.5,0,0,0,19.643,90.8V87.882A1.5,1.5,0,0,0,18.136,86.4H15.178a1.5,1.5,0,0,0-1.507,1.485V90.8a1.5,1.5,0,0,0,1.507,1.485h.7v2.572H9.365a5.893,5.893,0,0,1-4.027-1.575,5.484,5.484,0,0,1-1.777-3.843q0-.115,0-.231A6.652,6.652,0,0,1,9.8,82.623a.782.782,0,0,0,.564-.3.758.758,0,0,0,.149-.612,10.383,10.383,0,0,1-.187-2.02,10.247,10.247,0,0,1,1.439-5.171.76.76,0,0,0-.279-1.049.785.785,0,0,0-1.065.274,11.764,11.764,0,0,0-1.652,5.937,11.9,11.9,0,0,0,.087,1.5A8.188,8.188,0,0,0,2,89.2q0,.142,0,.283A7,7,0,0,0,4.27,94.393a7.457,7.457,0,0,0,5.1,1.993h6.515v4.533h-.7a1.5,1.5,0,0,0-1.507,1.485v.69h-7.7v-.69a1.5,1.5,0,0,0-1.507-1.485H1.507A1.5,1.5,0,0,0,0,102.4v2.914A1.5,1.5,0,0,0,1.507,106.8H4.465a1.5,1.5,0,0,0,1.507-1.485v-.69h7.7v.69a1.5,1.5,0,0,0,1.507,1.485h2.958a1.5,1.5,0,0,0,1.507-1.485V102.4a1.5,1.5,0,0,0-1.507-1.485h-.7V96.386H27.442v3.892h-.7a1.5,1.5,0,0,0-1.507,1.485v2.914a1.5,1.5,0,0,0,1.507,1.485h.7v4.16h-.7a1.5,1.5,0,0,0-1.507,1.485v.69H14.845v-.69a1.5,1.5,0,0,0-1.507-1.485H10.38a1.5,1.5,0,0,0-1.507,1.485v.69H.84a.767.767,0,1,0,0,1.533H8.873v.69A1.5,1.5,0,0,0,10.38,116.2h2.958a1.5,1.5,0,0,0,1.507-1.485v-.69H25.234v.69a1.5,1.5,0,0,0,1.507,1.485H29.7a1.5,1.5,0,0,0,1.507-1.485v-2.914a1.5,1.5,0,0,0-1.507-1.485H29v-4.16h.7a1.5,1.5,0,0,0,1.507-1.485v-2.914a1.5,1.5,0,0,0-1.507-1.485H29V96.386h4.241a.767.767,0,1,0,0-1.533ZM26.79,85.537H29.65v2.817H26.79ZM15.228,87.931h2.86v2.817h-2.86ZM4.416,105.269H1.556v-2.817h2.86Zm13.672,0h-2.86v-2.817h2.86Zm-4.8,9.4h-2.86v-2.817h2.86Zm16.361,0H26.791v-2.817H29.65Zm0-10.043H26.791v-2.817H29.65Z"
						transform="translate(0 -67.691)"
					/>
					<path
						d="M157.99,58.251h-9.481v-.79A1.5,1.5,0,0,0,147,55.977h-.7V47.252h2.612a7.456,7.456,0,0,0,5.1-1.993,7,7,0,0,0,2.264-4.907q0-.141,0-.283a8.191,8.191,0,0,0-7.486-8.1,8.211,8.211,0,0,0-8.244-7.728,8.391,8.391,0,0,0-3.2.636,12.138,12.138,0,0,0-10.07-6.227,12.311,12.311,0,0,0-9.059,3.385.759.759,0,0,0-.017,1.084.786.786,0,0,0,1.1.017,10.735,10.735,0,0,1,7.9-2.954,10.576,10.576,0,0,1,9.09,6.034.775.775,0,0,0,.461.4.789.789,0,0,0,.615-.054,6.785,6.785,0,0,1,3.183-.792,6.656,6.656,0,0,1,6.7,6.6c0,.085,0,.176-.008.294a.76.76,0,0,0,.215.564.784.784,0,0,0,.562.237h.017a6.656,6.656,0,0,1,6.689,6.6q0,.116,0,.231a5.486,5.486,0,0,1-1.777,3.844,5.893,5.893,0,0,1-4.027,1.575h-6.821a.767.767,0,1,0,0,1.533h2.654v8.724h-.7a1.5,1.5,0,0,0-1.507,1.485v2.914a1.5,1.5,0,0,0,1.507,1.485H147a1.5,1.5,0,0,0,1.507-1.485v-.591h9.481a.767.767,0,1,0,0-1.533Zm-11.037,2.076h-2.859V57.51h2.859Z"
						transform="translate(-106.438 -18.638)"
					/>
				</svg>
			)}
			{type === "cloud-database" && (
				<svg xmlns="http://www.w3.org/2000/svg" width="50.843" height="50.845" viewBox="0 0 50.843 50.845" {...rest}>
					<g transform="translate(-2.002 -2)">
						<path d="M52,34.2a.847.847,0,0,0,.847-.847V26.575A.847.847,0,0,0,52,25.727H26.575a.847.847,0,0,0-.847.847v2.542H21.491V24.88H35.049A6.779,6.779,0,1,0,32.33,11.893,11.851,11.851,0,0,0,10.375,7.932a8.474,8.474,0,0,0-.748,16.9V48.607a.847.847,0,0,0,.847.847H25.728V52a.847.847,0,0,0,.847.847H52A.847.847,0,0,0,52.845,52V45.218A.847.847,0,0,0,52,44.37H51.15v-.847H52a.847.847,0,0,0,.847-.847V35.9A.847.847,0,0,0,52,35.049H51.15V34.2ZM27.422,27.422H51.15v5.084H27.422Zm-6.779,3.39h5.084v2.542a.847.847,0,0,0,.847.847h.847v.847h-.847a.847.847,0,0,0-.847.847v2.542H16.406V24.88H19.8v5.084A.847.847,0,0,0,20.643,30.812ZM3.7,16.406a6.787,6.787,0,0,1,6.779-6.779c.069,0,.136.005.2.01l.145.008a.832.832,0,0,0,.8-.456,10.155,10.155,0,0,1,19.163,4.116.847.847,0,0,0,1.336.647,5.084,5.084,0,1,1,2.93,9.233H21.491V21.334a2.542,2.542,0,1,0-1.695,0v1.851h-3.39V18.792a2.542,2.542,0,1,0-1.695,0v4.393h-3.39V16.25a2.542,2.542,0,1,0-1.695,0v6.877A6.786,6.786,0,0,1,3.7,16.406Zm16.1,2.542a.847.847,0,1,1,.847.847A.847.847,0,0,1,19.8,18.948Zm-5.084-2.542a.847.847,0,1,1,.847.847A.847.847,0,0,1,14.711,16.406ZM9.627,13.864a.847.847,0,1,1,.847.847A.847.847,0,0,1,9.627,13.864Zm16.1,31.354V47.76H11.322V24.88h3.39V39.286a.847.847,0,0,0,.847.847H25.728v2.542a.847.847,0,0,0,.847.847h.847v.847h-.847A.847.847,0,0,0,25.728,45.218ZM51.15,51.15H27.422V46.065H51.15ZM49.455,44.37H29.117v-.847H49.455Zm1.695-2.542H27.422V36.744H51.15Zm-1.695-6.779H29.117V34.2H49.455Z" />
						<path d="M35,34h1.695v1.695H35Z" transform="translate(-5.035 -4.883)" />
						<path d="M39,34h1.695v1.695H39Z" transform="translate(-5.646 -4.883)" />
						<path d="M43,34h1.695v1.695H43Z" transform="translate(-6.256 -4.883)" />
						<path d="M35,45h1.695v1.695H35Z" transform="translate(-5.035 -6.561)" />
						<path d="M39,45h1.695v1.695H39Z" transform="translate(-5.646 -6.561)" />
						<path d="M43,45h1.695v1.695H43Z" transform="translate(-6.256 -6.561)" />
						<path d="M35,56h1.695v1.695H35Z" transform="translate(-5.035 -8.24)" />
						<path d="M39,56h1.695v1.695H39Z" transform="translate(-5.646 -8.24)" />
						<path d="M43,56h1.695v1.695H43Z" transform="translate(-6.256 -8.24)" />
					</g>
				</svg>
			)}
			{type === "mail" && (
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" {...rest}>
					<g>
						<g>
							<path
								d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106
			C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z
			 M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787
			L338.213,256L482,112.212V399.787z"
							/>
						</g>
					</g>
				</svg>
			)}
			{type === "phone" && (
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 482.6 482.6" {...rest}>
					<g>
						<path
							d="M98.339,320.8c47.6,56.9,104.9,101.7,170.3,133.4c24.9,11.8,58.2,25.8,95.3,28.2c2.3,0.1,4.5,0.2,6.8,0.2
		c24.9,0,44.9-8.6,61.2-26.3c0.1-0.1,0.3-0.3,0.4-0.5c5.8-7,12.4-13.3,19.3-20c4.7-4.5,9.5-9.2,14.1-14
		c21.3-22.2,21.3-50.4-0.2-71.9l-60.1-60.1c-10.2-10.6-22.4-16.2-35.2-16.2c-12.8,0-25.1,5.6-35.6,16.1l-35.8,35.8
		c-3.3-1.9-6.7-3.6-9.9-5.2c-4-2-7.7-3.9-11-6c-32.6-20.7-62.2-47.7-90.5-82.4c-14.3-18.1-23.9-33.3-30.6-48.8
		c9.4-8.5,18.2-17.4,26.7-26.1c3-3.1,6.1-6.2,9.2-9.3c10.8-10.8,16.6-23.3,16.6-36s-5.7-25.2-16.6-36l-29.8-29.8
		c-3.5-3.5-6.8-6.9-10.2-10.4c-6.6-6.8-13.5-13.8-20.3-20.1c-10.3-10.1-22.4-15.4-35.2-15.4c-12.7,0-24.9,5.3-35.6,15.5l-37.4,37.4
		c-13.6,13.6-21.3,30.1-22.9,49.2c-1.9,23.9,2.5,49.3,13.9,80C32.739,229.6,59.139,273.7,98.339,320.8z M25.739,104.2
		c1.2-13.3,6.3-24.4,15.9-34l37.2-37.2c5.8-5.6,12.2-8.5,18.4-8.5c6.1,0,12.3,2.9,18,8.7c6.7,6.2,13,12.7,19.8,19.6
		c3.4,3.5,6.9,7,10.4,10.6l29.8,29.8c6.2,6.2,9.4,12.5,9.4,18.7s-3.2,12.5-9.4,18.7c-3.1,3.1-6.2,6.3-9.3,9.4
		c-9.3,9.4-18,18.3-27.6,26.8c-0.2,0.2-0.3,0.3-0.5,0.5c-8.3,8.3-7,16.2-5,22.2c0.1,0.3,0.2,0.5,0.3,0.8
		c7.7,18.5,18.4,36.1,35.1,57.1c30,37,61.6,65.7,96.4,87.8c4.3,2.8,8.9,5,13.2,7.2c4,2,7.7,3.9,11,6c0.4,0.2,0.7,0.4,1.1,0.6
		c3.3,1.7,6.5,2.5,9.7,2.5c8,0,13.2-5.1,14.9-6.8l37.4-37.4c5.8-5.8,12.1-8.9,18.3-8.9c7.6,0,13.8,4.7,17.7,8.9l60.3,60.2
		c12,12,11.9,25-0.3,37.7c-4.2,4.5-8.6,8.8-13.3,13.3c-7,6.8-14.3,13.8-20.9,21.7c-11.5,12.4-25.2,18.2-42.9,18.2
		c-1.7,0-3.5-0.1-5.2-0.2c-32.8-2.1-63.3-14.9-86.2-25.8c-62.2-30.1-116.8-72.8-162.1-127c-37.3-44.9-62.4-86.7-79-131.5
		C28.039,146.4,24.139,124.3,25.739,104.2z"
						/>
					</g>
				</svg>
			)}
			{type === "warning" && (
				<svg id="Layer_1" height="512" viewBox="0 0 512.07 512.07" width="512" xmlns="http://www.w3.org/2000/svg" {...rest}>
					<g>
						<path d="m256.035 320.004c-8.837 0-16-7.164-16-16v-112c0-8.836 7.163-16 16-16s16 7.164 16 16v112c0 8.837-7.163 16-16 16z" />
						<path d="m505.296 400.334-199.19-331.98c-22.6-37.681-77.496-37.754-100.14 0l-199.191 331.98c-18.837 31.395 3.826 71.67 40.58 71.67h417.36c36.76 0 59.414-40.28 40.581-71.67zm-40.581 39.67h-417.359c-11.922 0-19.232-13.07-13.14-23.21l199.19-331.98c10.219-17.033 35.028-17.053 45.26 0l199.19 331.98c6.085 10.129-1.208 23.21-13.141 23.21z" />
						<circle cx="256.035" cy="360.004" r="24" />
					</g>
				</svg>
			)}
			{type === "check" && (
				<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" {...rest}>
					<g>
						<g>
							<path
								d="M387.106,167.497c-5.857-5.857-15.354-5.858-21.212,0.001L220.705,312.685l-74.599-74.597
			c-5.857-5.857-15.355-5.858-21.213,0.001c-5.857,5.858-5.857,15.355,0,21.213l85.205,85.203c2.929,2.929,6.768,4.393,10.606,4.393
			c3.839,0,7.678-1.464,10.606-4.394L387.106,188.71C392.964,182.853,392.964,173.355,387.106,167.497z"
							/>
						</g>
					</g>
					<g>
						<g>
							<path
								d="M437.019,74.981C388.667,26.629,324.379,0,256,0C187.62,0,123.333,26.629,74.981,74.981C26.628,123.334,0,187.621,0,256
			c0,68.381,26.628,132.668,74.981,181.02C123.332,485.372,187.62,512,256,512s132.667-26.628,181.019-74.98
			C485.371,388.668,512,324.381,512,256C512,187.621,485.371,123.334,437.019,74.981z M256,482C131.383,482,30,380.617,30,256
			S131.383,30,256,30s226,101.383,226,226S380.617,482,256,482z"
							/>
						</g>
					</g>
				</svg>
			)}
			{type === "pdf" && (
				<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" {...rest}>
					<path style={{ fill: "#E2E5E7" }} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z" />
					<path style={{ fill: "#B0B7BD" }} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
					<polygon style={{ fill: "#CAD1D8" }} points="480,224 384,128 480,128 " />
					<path
						style={{ fill: "#F15642" }}
						d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
	V416z"
					/>
					<g>
						<path
							style={{ fill: "#FFFFFF" }}
							d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48
		c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z
		 M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
						/>
						<path
							style={{ fill: "#FFFFFF" }}
							d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296
		c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
						/>
						<path
							style={{ fill: "#FFFFFF" }}
							d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68
		h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912
		c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
						/>
					</g>
					<path style={{ fill: "#CAD1D8" }} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z" />
				</svg>
			)}
			{type === "checked" && (
				<svg xmlns="http://www.w3.org/2000/svg" width="88.956" height="89.074" viewBox="0 0 88.956 89.074" {...rest}>
					<path
						className="a-checked"
						d="M79.549,81.272c-13.63,11.126-56.188,11.126-69.54,0-13.63-11.126-12.239-58.692,0-71.209s57.3-12.517,69.54,0S93.179,70.146,79.549,81.272Z"
						transform="translate(-0.301 -0.543)"
					/>
					<g className="b-checked" transform="translate(0.041)">
						<path
							className="c-checked"
							d="M45.225,61.258C32.986,70.994,19.356,76,6.561,76.279-2.9,60.145-.95,20.925,10.177,9.52,20.19-.771,51.622-2.719,69.7,3.679,74.153,22.038,64.974,45.4,45.225,61.258Z"
							transform="translate(-0.509 0)"
						/>
					</g>
					<path
						className="c-checked"
						d="M104.7,150.883a5.948,5.948,0,0,1-5.007-2.5L84.113,128.352a6.343,6.343,0,1,1,10.014-7.788l10.57,13.63,23.366-30.319a6.343,6.343,0,0,1,10.014,7.788L109.7,148.38A7.275,7.275,0,0,1,104.7,150.883Z"
						transform="translate(-66.616 -81.559)"
					/>
				</svg>
			)}
			{type === "comment" && (
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="16.56" viewBox="0 0 18 16.56" {...rest}>
					<g transform="translate(-0.001 1.332)">
						<path
							className="a"
							d="M15.526-1.332H2.475A2.477,2.477,0,0,0,0,1.143V9.129A2.478,2.478,0,0,0,2.467,11.6v3.624L7.675,11.6h7.851A2.478,2.478,0,0,0,18,9.129V1.143a2.477,2.477,0,0,0-2.475-2.475Zm1.42,10.461a1.422,1.422,0,0,1-1.42,1.42H7.344L3.521,13.21v-2.66H2.475a1.422,1.422,0,0,1-1.42-1.42V1.143a1.422,1.422,0,0,1,1.42-1.42H15.526a1.422,1.422,0,0,1,1.42,1.42Zm0,0"
						/>
						<path className="a" d="M171.293,131.172h8.365v1.055h-8.365Zm0,0" transform="translate(-166.475 -128.777)" />
						<path className="a" d="M171.293,211.172h8.365v1.055h-8.365Zm0,0" transform="translate(-166.475 -206.527)" />
						<path className="a" d="M171.293,291.172h8.365v1.055h-8.365Zm0,0" transform="translate(-166.475 -284.277)" />
					</g>
				</svg>
			)}
		</>
	);
};

export default Icon;
