import React, { useContext, useState } from "react";
//i18n
import { withNamespaces } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import logoDark from "../../../assets/images/logo-dark-full.svg";
// users
import defaultAvatar from "../../../assets/images/users/avatar.png";
import { AuthContext } from "../../../context";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"

  const [modal, setmodal] = useState(false);
  const { logoutUser, user } = useContext(AuthContext);
  const [menu, setMenu] = useState(false);
  const history = useHistory();

  const logoutOnClick = () => {
    logoutUser();
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user?.photo_url ? user?.photo_url : defaultAvatar}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ml-2 mr-1">
            {user?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem
            tag="a"
            href="/account/"
            onClick={(e) => {
              e.preventDefault();
              history.push("/account/");
            }}
          >
            {" "}
            <i className="mdi mdi-account font-size-16 align-middle mr-1"></i>
            {props.t("My Profile")}{" "}
          </DropdownItem>

          {/* <DropdownItem tag="a" href="#">
						{" "}
						<i className="mdi mdi-cog-outline font-size-16 align-middle mr-1"></i>
						{props.t("Settings")}
					</DropdownItem> */}

          <div className="dropdown-divider"></div>
          <Link
            to="/logout"
            className="dropdown-item"
            onClick={(e) => {
              e.preventDefault();
              setmodal(true);
            }}
          >
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Modal
        size="md"
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <ModalHeader
          className="border-0 bg-light"
          toggle={() => {
            setmodal(!modal);
          }}
        >
          <div className="d-flex align-items-center ">
            <div className="avatar-xs mr-3">
              <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                <i className="mdi mdi-login-variant"></i>
              </span>
            </div>
            <h5 className="mb-0"> Sign out</h5>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src={logoDark} alt="" width="110px" />
            <h5 className="text-center logout-confirm-text mt-4 mb-4">
              Log out of FlipServe ?
            </h5>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary mr-2  btn-w-min"
                onClick={() => {
                  setmodal(!modal);
                }}
              >
                Not Now
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-danger ml-2 btn-w-min"
                onClick={logoutOnClick}
              >
                Logout
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withNamespaces()(ProfileMenu))
);
