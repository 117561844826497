import React, { useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import logoDark from "../../assets/images/logo-dark-full-211x50px.png";
import Icon from "../../components/Icon";
import { PATHS } from "../../config";
import { AuthContext } from "../../context";

const SelectRole = () => {
	const { user } = useContext(AuthContext);

	if (user) {
		return <Redirect to={PATHS.dashboard} />;
	}

	return (
		<>
			<main className="main auth">
				<div className="left-pane-white">
					<div className="d-flex flex-column align-items-center justify-content-center h-100">
						<img src={logoDark} alt="logo" className="left-pane-white-logo mb-5" />
						<p className="display-4 mb-3">Defining iSaaS</p>
						<p>
							Join the fastest growing digital platform for Infrastructure Services. Follow the easy sign up process and onboard your cloud
							services to our platform.
						</p>
					</div>
				</div>
				<div className="right-pane">
					<div className="d-flex flex-column align-items-center justify-content-center h-100">
						<div>
							<h3 className="text-dark display-4 font-weight-bold mb-5">In FlipServe, I want to</h3>
							<div className="d-flex signup-options-container">
								<Link to="/auth/register/customer" className="mr-3 signup-option">
									<div>
										<div className="icon-wrapper mb-4">
											<Icon type="cloud-connection" width="4rem" height="4rem" />
										</div>
										<p className="mb-0">Customer Signup</p>
									</div>
								</Link>
								<Link to="/auth/register/provider" className="ml-3 signup-option">
									<div>
										<div className="icon-wrapper mb-4">
											<Icon type="cloud-database" width="4rem" height="4rem" />
										</div>
										<p className="mb-0">Provider Signup</p>
									</div>
								</Link>
							</div>
						</div>
						<div className="mt-5">
							<p className="text-center">
								Already have an account?{" "}
								<span>
									<Link to="/auth/login" className="font-weight-bold text-primary text-underline">
										Log In
									</Link>
								</span>
							</p>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default SelectRole;
