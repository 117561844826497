import React from "react";
import { Link } from "react-router-dom";
import nomad from "../../assets/images/icons/digital-nomad.svg";
import salary from "../../assets/images/icons/salary.svg";
import team from "../../assets/images/icons/team.svg";
import working from "../../assets/images/icons/working.svg";
import logoDark from "../../assets/images/logo-dark-full-211x50px.png";

const PartnerInfo = ({ type }) => {
	return (
		<React.Fragment>
			<div className="d-flex flex-column  justify-content-center h-100">
				<img src={logoDark} alt="logo" className="left-pane-white-logo mb-1" />
				{type === "PartnerSignup" && <h5 className="display-4 mb-1 mt-3 font-size-24">Why Partner With FlipServe?</h5>}
				{type === "PartnerLogin" && <h5 className="display-4 mb-1 mt-3 font-size-24">Start your free subscription plan</h5>}

				<p>Once you signup with us , you will receive the following benefits through our global citizenship program.</p>

				<br />
				<table className="table">
					<tbody>
						<tr>
							<td style={{ width: "40px" }}>
								{" "}
								<img src={nomad} alt="client-img" style={{ width: "30px" }} />
							</td>
							<td>
								Work from anywhere
								<br />
								<span className="text-muted">Do meaningful work from the comfort of your home on a secure and global Platform</span>
							</td>
						</tr>
						<tr>
							<td style={{ width: "40px" }}>
								{" "}
								<img src={working} alt="client-img" style={{ width: "30px" }} />
							</td>
							<td>
								Set your own schedule
								<br />
								<span className="text-muted">Complete tasks when they work for you</span>
							</td>
						</tr>
						<tr>
							<td style={{ width: "40px" }}>
								{" "}
								<img src={salary} alt="client-img" style={{ width: "30px" }} />
							</td>
							<td>
								Get paid by outcome
								<br />
								<span className="text-muted">Receive money as soon as the service is completed</span>
							</td>
						</tr>
						<tr>
							<td style={{ width: "40px" }}>
								{" "}
								<img src={team} alt="client-img" style={{ width: "30px" }} />
							</td>
							<td>
								Global Community
								<br />
								<span className="text-muted">
									Get access to the latest technological platform and community through our global citizenship program
								</span>
							</td>
						</tr>
					</tbody>
				</table>
				{type === "PartnerLogin" && (
					<div>
						<Link to="/auth/register/customer" className="btn btn-primary mt-1">
							Signup for free
						</Link>
					</div>
				)}

				{type === "PartnerSignup" && (
					<div>
						<h5 className="display-4 mb-3 mt-1 font-size-24">Next Steps </h5>
						Once you have signed up successfully, we’ll reach out to you to get you started on the program.
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export default PartnerInfo;
