import React from "react";
import { Alert } from "reactstrap";
import { useLocation } from "react-router-dom";
import { PageContainer } from "../../components/Common/PageContainer";
import { PowerBI } from "./powerbi";

const Main = () => {
  const { pathname } = useLocation();

  return (
    <div style={{ display: pathname === "/dashboard" ? "block" : "none" }}>
      <PageContainer
        title="Dashboard"
        subtitle="Overview of your infrastructure"
        icon="mdi mdi-currency-usd-circle"
        hideplans={true}
        hideplans_filter={true}
        hidesubscriptions_filter={true}
        hide_comingsoon_note = {true}
        component={(props) => (
          <Alert color="secondary" className="mb-2" role="alert">
            <i className="mdi mdi-alert-circle-outline mr-2"></i>
            This is a sample dashboard. Please get in touch with
            support@flipserve.com to setup the dashboard for your account.
          </Alert>
        )}
      />
      <PowerBI />
    </div>
  );
};

export default Main;
