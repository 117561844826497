// MetisMenu
import MetisMenu from "metismenujs";
import React, { useContext, useEffect } from "react";
//i18n
import { withNamespaces } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { AuthContext } from "../../context";

const SidebarContent = (props) => {
  const { role } = useContext(AuthContext);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
        } else dectivateParentDropdown(items[i]);
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  }

  function dectivateParentDropdown(item) {
    item.classList.remove("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.remove("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.remove("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.remove("mm-active"); // li
          parent3.childNodes[0].classList.remove("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.remove("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <hr />
          {/* <li className="menu-title">{props.t("Menu")} </li> */}
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="mdi mdi-monitor-dashboard"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          {/* <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="mdi mdi-home"></i>
              <span>Home</span>
            </Link>
          </li> */}

          {/* <li>
						<Link to="/servers" className="waves-effect">
							<i className="mdi mdi-server"></i>
							<span>Infrastructure</span>
						</Link>
					</li> */}
          <li>
            <Link to="/subscriptions" className="waves-effect">
              <i className="mdi mdi-alpha-s-box"></i>
              <span>Subscriptions</span>
            </Link>
          </li>

          <li>
            <Link to="/monitoring" className="waves-effect">
              <i className="mdi mdi-pulse"></i>
              <span>Daily Monitoring</span>
            </Link>
          </li>

          {/* <li>
            <Link to="/recommendations" className="waves-effect">
              <i className="mdi mdi-file-key-outline"></i>
              <span>Credentials</span>
            </Link>
          </li> */}
          <li>
            <Link to="/recommendations" className="waves-effect">
              <i className="mdi mdi-bullseye-arrow"></i>
              <span>Advisor Reports</span>
            </Link>
          </li>

          {/*}
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="mdi mdi-chart-donut-variant"></i>
              <span>Dashboards</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/cost" className="waves-effect">
                  <span>Cost Analysis</span>
                </Link>
              </li>
              <li>
                <Link to="/performance" className="waves-effect">
                  <span>Performance</span>
                </Link>
              </li>
            </ul>
          </li>
*/}
          <li>
            <Link to="/alerts" className="waves-effect">
              <i className="mdi mdi-chat-alert-outline"></i>
              <span>Alerts</span>
            </Link>
          </li>
          <li>
            <Link to="/support" className="waves-effect">
              <i className="mdi mdi-chat-alert-outline"></i>
              <span>Support</span>
            </Link>
          </li>
          <hr />
          {role === "owner" && (
            <li>
              <Link to="/setup" className="waves-effect">
                <i className="mdi mdi-cog-outline"></i>
                <span>FlipServe Setup</span>
              </Link>
            </li>
          )}
          {role === "owner" && (
            <li>
              <Link to="/quote" className="waves-effect">
                <i className="mdi mdi-inbox-arrow-up"></i>
                <span>Quote Requests</span>
              </Link>
            </li>
          )}
          {role === "owner" && (
            <li>
              <Link to="/credentials" className="waves-effect">
                <i className="mdi mdi-shield-lock-outline"></i>
                <span>Credentials</span>
              </Link>
            </li>
          )}
          <li>
            <Link to="/account/" className="waves-effect">
              <i className="mdi mdi-account"></i>
              <span>My Profile</span>
            </Link>
          </li>
          {/*
          <li>
            <Link to="/reset" className="waves-effect text-danger">
              <i className="mdi mdi-alert text-danger"></i>
              <span>Testing</span>
            </Link>
          </li>
          */}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(SidebarContent));
