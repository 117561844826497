import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { PATHS } from "../../config";
import { AuthContext } from "../../context";

const AuthMiddleware = ({
  component: Component = null,
  render: Render = null,
  layout: Layout,
  path,
  exact,
  ...rest
}) => {
  const { is_authenticated } = useContext(AuthContext);

  const routeComponent = (props) =>
    is_authenticated ? (
      Render ? (
        Render(props)
      ) : Component ? (
        Layout ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        )
      ) : null
    ) : (
      <Redirect
        to={{ pathname: PATHS.authRedirect, state: { from: props.location } }}
      />
    );
  return <Route path={path} exact={exact} {...rest} render={routeComponent} />;
};

export default AuthMiddleware;
