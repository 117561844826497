import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Spinner } from "reactstrap";
import logoDark from "../../assets/images/logo-dark-full-211x50px.png";
import { APP_CONFIG, PATHS } from "../../config";
import { AuthContext } from "../../context";
import { useFormValidation } from "../../hooks";
import CustomerInfo from "./CustomerInfo";

const ForgetPassword = () => {
	const { user } = useContext(AuthContext);

	const [formFields, setFormFields] = useState({
		email: "",
		isSubmitting: false,
	});

	const [formFieldErrors, onBlur] = useFormValidation({
		email: "",
	});

	const onChange = (e) => {
		setFormFields({ ...formFields, [e.target.name]: e.target.value });
		onBlur(e);
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		const { email } = formFields;
		if (
			[...Object.values(formFieldErrors)].every((err) => {
				return err ? false : true;
			}) &&
			email
		) {
			setFormFields({ ...formFields, isSubmitting: true });
			try {
				const response = await axios({
					method: "POST",
					url: `${APP_CONFIG.REACT_APP_SERVICES_BASE_URL}/user/reset-password`,
					data: {
						action: "start",
						email,
					},
				});
				cogoToast.info(response.data.message, { position: "top-right" });
			} catch (err) {
				cogoToast.error(err.response.data.message, { position: "top-right" });
			}
			setFormFields({ ...formFields, isSubmitting: false });
		}
	};

	if (user) {
		return <Redirect to={PATHS.dashboard} />;
	}

	return (
		<>
			<main className="main auth">
				<div className="left-pane-white">
					<CustomerInfo type="CustomerLogin" />
				</div>
				<div className="right-pane">
					<div className="d-flex flex-column justify-content-center h-100">
						<img src={logoDark} alt="FlipServe logo" className="d-none right-pane-logo align-self-center" />

						<div>
							<h3 className="text-dark display-4 mb-3 font-weight-bold">Forgot Password?</h3>
							<p className="mb-1">Reset Your Password</p>
							<p className="mb-4 text-gray text-sm">
								<span>Enter the email associated with your account. We will email you a link to reset your password </span>
							</p>
							<form onSubmit={onSubmit}>
								<div className={formFieldErrors.email ? "form-group has-error" : "form-group"}>
									<label htmlFor="email">Email ID</label>
									<input
										type="email"
										name="email"
										id="email"
										className="form-control"
										placeholder="name@domain.com"
										value={formFields.email}
										onChange={onChange}
										onBlur={onBlur}
									/>
									{formFieldErrors.email && <p className="form-validation-message">{formFieldErrors.email}</p>}
								</div>
								<button type="submit" className="btn btn-block btn-primary" onClick={onSubmit} disabled={formFields.isSubmitting}>
									{formFields.isSubmitting ? <Spinner size="sm" role="status" /> : <span>Reset</span>}
								</button>
							</form>
						</div>
						<div className="mt-5">
							<p className="text-center">
								<span>
									<Link to={PATHS.login} className="text-primary ">
										Login here
									</Link>
								</span>
							</p>
						</div>
					</div>
				</div>
			</main>
		</>
	);
};

export default ForgetPassword;
