import { gql } from "@apollo/client";

export const GET_TICKETS = gql`
	query GetTickets($where: support1_tickets_bool_exp, $order_by: [support_tickets_order_by!]) {
		objects: support_tickets(where: $where, order_by: $order_by) {
			body
			id

			level
			priority
			requester_id
			created_at
			updated_at
			assigned_to
			status
			ticket_status {
				name
			}
			ticket_type {
				name
			}
			title
			type
		}
	}
`;

export const ADD_SUPPORT_TICKET = gql`
	mutation ADD_SUPPORT_TICKET($title: String!, $body: String!, $priority: String!, $type: Int!) {
		insert_support_tickets_one(object: { title: $title, body: $body, priority: $priority, level: "4", type: $type }) {
			id
		}
	}
`;

export const ADD_TICKET_COMMENT = gql`
	mutation ADD_TICKET_COMMENT($parent_id: Int!, $body: String) {
		insert_comments_one(object: { parent_id: $parent_id, body: $body }) {
			id
			parent_id
			body
		}
	}
`;

export const GET_TICKET_AND_COMMENTS = gql`
	query FETCH_TICKET_AND_COMMENTS($ticket_id: Int!) {
		support_tickets_by_pk(id: $ticket_id) {
			id
			title
			body
			requester_id
			created_at
			updated_by
			updated_at
			priority
			type
			user {
				id
				name
				photo_url
			}
			comments(where: { deleted_by: { _is_null: true } }) {
				id
				parent_id
				body
				created_at
				created_by
				updated_by
				updated_at
				user {
					id
					name
					photo_url
				}
			}
		}
	}
`;

export const ARCHIVE_TICKET_COMMENT = gql`
	mutation ARCHIVE_TICKET_COMMENT($comment_id: bigint!, $deleted_by: uuid!, $deleted_at: timestamptz!) {
		update_comments(where: { id: { _eq: $comment_id } }, _set: { deleted_by: $deleted_by, deleted_at: $deleted_at }) {
			returning {
				id
			}
		}
	}
`;

export const ARCHIVE_TICKET_AND_COMMENTS = gql`
	mutation ARCHIVE_TICKET_AND_COMMENTS($ticket_id: Int!, $deleted_at: timestamptz!, $deleted_by: uuid!) {
		update_support_tickets(where: { id: { _eq: $ticket_id } }, _set: { deleted_at: $deleted_at, deleted_by: $deleted_by }) {
			affected_rows
		}
		update_comments(where: { parent_id: { _eq: $ticket_id } }, _set: { deleted_by: $deleted_by, deleted_at: $deleted_at }) {
			affected_rows
		}
	}
`;

export const UPDATE_TICKET = gql`
	mutation($ticket_id: Int!, $title: String!, $body: String!, $priority: String!, $type: Int!, $updated_at: timestamptz!, $updated_by: uuid!) {
		update_support_tickets_by_pk(
			pk_columns: { id: $ticket_id }
			_set: { title: $title, body: $body, priority: $priority, type: $type, updated_at: $updated_at, updated_by: $updated_by }
		) {
			id
		}
	}
`;

export const UPDATE_COMMENT = gql`
	mutation($comment_id: bigint!, $body: String!, $updated_at: timestamptz!, $updated_by: uuid!) {
		update_comments_by_pk(pk_columns: { id: $comment_id }, _set: { body: $body, updated_at: $updated_at, updated_by: $updated_by }) {
			id
		}
	}
`;
