import { gql } from "@apollo/client";

export const RESET_ACCOUNT = gql`
mutation delete_null_geom($tenant_id: uuid!) {
  delete_subscriptions(where: {tenant_id: {_eq: $tenant_id}}) {
    affected_rows
  }
  delete_servers(where: {tenant_id: {_eq: $tenant_id}}) {
    affected_rows
  }
  delete_service_subscriptions(where: {tenant_id: {_eq: $tenant_id}}) {
    affected_rows
  }
  delete_services(where: {tenant_id: {_eq: $tenant_id}}) {
    affected_rows
  }
  }
`;

