export const PATHS = {
	login: "/auth/login",
	registerConsumer: "/auth/register/consumer",
	registerProvider: "/auth/register/provider",
	selectRole: "/auth/select-role",
	authRedirect: "/auth/redirect",
	verifyPhone: "/auth/verify-phone",
	verifyAccount: "/auth/verify-account",
	dashboard: "/dashboard",
	setup: "/setup",
	forgotPassowrd: "/auth/forgot-password",
};

export const APP_CONFIG = {
	NAME: "FlipServe",
	PROTOCOL: "https",
	HOST: "127.0.0.1",
	PORT: "3000",
	...process.env,
};
