import React from "react";

const List = ({ csp_tenant_id, csp_type, price_plan_id, service_subscription_id, date, ...props }) => {
	return (
		<React.Fragment>
			<h5>Waiting on UI / UX Design</h5>
		</React.Fragment>
	);
};

export default List;
